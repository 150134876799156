import React, {useEffect, useReducer, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from 'src/features/P01Solicitud/subcomponents/Solicitud'
import {
  handCompletarTareaCoordinador,
  handleClear,
  handleSaveSolicitud
} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import {MyGobackButton, MySaveButton} from "src/components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {P0102Expediente} from "./P0102Expediente";

const reducer = (state, action) => {
  switch (action.type) {
    case "after-firmar":
      return {
        ...state,
        firmando: action.fv.firmando,
      }
    default:
      throw Error('Unknown action: ' + action.type)
  }
}

const P01SolicitudEmitirDictamenTecnicoSENADI = () => {
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const section = `EmitirDictamenTecnico${perfilUsuario?.perfil?.organizacion?.nombreOrganizacion}`
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  payload.solicitudId = solicitud.id
  const inputRef = useRef()
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const subfolder = 'dictamen-tecnico-Senadi'
  const filename = `dictamen-tecnico-${solicitud.numeroSolicitud}-Senadi`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const emptyPayload = {
    fecha: today,
    serial: payload['DictamenTecnicoSenadi']?.serial,
    identificador: solicitud.numeroSolicitud,
    asunto: '',
    pdfGenerado: false,
    docx: payload?.DictamenTecnicoSenadi?.docx,
    pdf: payload?.DictamenTecnicoSenadi?.pdf,
    docxLded: payload?.DictamenTecnicoSenadi?.docxLded,
    pdfLded: payload?.DictamenTecnicoSenadi?.pdfLded,

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//
  }
  let myPayload = payload
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => { setMytab(newTab) }

  const getOnSave = (setCounter, fv=null) => {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }

  // Test firma electronica ---\\
  useEffect(() => {
    if(formValues.firmando && !formValues.firmado) {
      const testFirmas = async () => {
        API.testFirmarPdf(instanciaTarea.id).then((res) => {
          if(res?.resultado === true) {
            API.testDocFirmadoPdf(instanciaTarea.id).then((signedPdf) => {
              if(f.isValidNotEmpty(signedPdf?.rutaDocumento)) {
                const ruta = signedPdf?.rutaDocumento?.split('/')
                const newSignedDoc = ruta[ruta.length - 1]
                const newSubFolder = ruta[ruta.length - 2]
                const fv = {
                  ...formValues,
                  firmando:false,
                  firmado:true,
                  pdfSigned: newSignedDoc,
                  rudaDocumentoFirmado: signedPdf?.rutaDocumento,
                  subfolder: newSubFolder
                }
                setFormValues(fv)
                getOnSave(setCounter,fv)()
              }
            })
          } else {
            console.log(`${instanciaTarea.id} aún no está firmado . . . `)
          }
        })
      }
      testFirmas().then(r => r)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.firmando])
  const [, dispatch2] = useReducer(reducer, {firmando: formValues.firmando})
  // Test firma electronica ---//

  console.log('::::: filenamePDFsigned ', filenamePDFsigned)

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        {/*<SectionTitle title={'Emitir Dictamen Técnico---2'} onClick={()=> { dispatch(handleClear()) }} />*/}
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            background: 'transparent'
          }}>
          <SectionTitle title={'Emitir Dictamen Técnico'} />
          <Typography sx={{m:'10px 10px 0 0', fontSize:'0.4rem'}}>{'id: ' + instanciaTarea.id}</Typography>
        </Stack>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <Tab label={`Solicitud ${payload?.solicitudOriginal?.solicitudNumeroSolicitud}`} value="4"/> : null
            }
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <Tab label={`Expediente ${payload?.solicitudOriginal?.solicitudNumeroSolicitud}`} value="5"/> : null
            }
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Emisión Dictamen Técnico'} />
            </Grid>
            <Grid item xs={4}>
              <MyReadOnlyTextField id={'fecha'}
                                   label={'Fecha'}
                                   icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />}
                                   value={formValues.fecha} />
            </Grid>
            <Grid item xs={4} >
                <MyReadOnlyTextField id={'identificador'}
                                     label={'Identificador'}
                                     icon={<AssignmentIndOutlinedIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />}
                                     value={formValues.identificador} />
            </Grid>
            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 1rem'}}>
                {
                  formValues.docx !== '' &&
                  <>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadDOCX({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                    })}
                                    toolTip={'Descagar documento en formato docx'}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    toolTip={'Descagar documento en formato pdf'}
                                    width={'5rem'} />
                  </>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'DOCX'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenameDOCXuploaded}
                                        afterUpload={() => {
                                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                            if(f.isValid(result2.rutaDocumento)) {
                                              const arr2 = result2.rutaDocumento.split('/')
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                            } else {
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                            }
                                          })
                                        }}
                                        toolTip={'Subir documento modificado en formato docx'}
                                        disabled={formValues.firmado}
                                        width={'5rem'} />
                  </ButtonGroup>
                }
                <ButtonGroup variant={'contained'}>
                  <MyFileUploadButton inputRef={inputRef}
                                      label={'PDF (firmado)'}
                                      solicitudId={instanciaProceso?.solicitud?.id}
                                      subfolder={subfolder}
                                      fileName={filenamePDFsigned}
                                      afterUpload={() => {
                                        setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                      }
                                      toolTip={'Subir documento firmado en formato pdf'}
                                      width={'9rem'}
                                      disabled={formValues.firmando || formValues.firmado}
                  />

                  <MyButtonBacan3 label={'FIRMAR'}
                                  icon={FileDownloadIcon}
                                  onClick={()=> {
                                    API.firmarPdf(instanciaTarea.id, subfolder, (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf).then((res)=> {
                                      if(res?.length > 0) {
                                        const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                        const firmaTareaId = res[0].firmaTareaId
                                        const fv = {
                                          ...formValues,
                                          firmando:true,
                                          firmado:false,
                                          pdfSigned,
                                          firmaTareaId
                                        }
                                        setFormValues(fv)
                                        dispatch2({type: 'after-firmar', fv})
                                        getOnSave(setCounter,fv)()
                                      }
                                    })
                                  }}
                                  disabled={formValues.firmando || formValues.firmado}
                                  toolTip={'firmar electronicamente el documento'}
                                  width={'9rem'} />

                  {
                    formValues.firmado && <MyButtonBacan3 label={''}
                                                          icon={FileDownloadIcon}
                                                          onClick={() => API.fetchDownloadPDF({
                                                            solicitudId: instanciaProceso?.solicitud?.id,
                                                            subfolder,
                                                            filename: filenamePDFsigned,
                                                          })}
                                                          toolTip={'Descargar documento firmado'}
                                                          width={'3rem'}/>
                  }
                </ButtonGroup>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={myPayload}/>
        </TabPanel>
        <TabPanel value="3">
          <P0102Expediente payload={payload}/>
        </TabPanel>
        {
          f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
            <TabPanel value="4">
              <P0102PayloadRO payload={payload.solicitudOriginal.payloadSolicitud}/>
            </TabPanel> : null
        }
        {
          f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
            <TabPanel value="5">
              <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
            </TabPanel> : null
        }
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} >
              <Stack direction={'row'}
                     spacing={0}
                     justifyContent="space-between"
                     alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                payload[section]=formValues
                                dispatch(handleSaveSolicitud(
                                  instanciaProceso?.id,
                                  payload,
                                  () => setCounter(0)
                                ))
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({"solicitudId":`${instanciaProceso?.solicitud?.id}`})
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              disabled={counter > 0 || formValues.pdfSigned === '' || !formValues.firmado} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

export default  P01SolicitudEmitirDictamenTecnicoSENADI
