import React, {useEffect, useReducer, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from 'src/features/P01Solicitud/subcomponents/Solicitud'
import {
  handCompletarTareaCoordinador,
  handleClear,
  handleSaveSolicitud
} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import {MyGobackButton, MySaveButton} from "src/components/MyCommonButtons";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import P0102API from './API'
import GLOBALS from "../App/globals";
import {P0102Expediente} from "./P0102Expediente";

const reducer = (state, action) => {
  switch (action.type) {
    case "after-firmar":
      return {
        ...state,
        firmando: action.fv.firmando,
      }
    default:
      throw Error('Unknown action: ' + action.type)
  }
}

const P01SolicitudDictamenTecnico = ({id}) => {
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const section = `DictamenTecnico${perfilUsuario?.perfil?.organizacion?.nombreOrganizacion}`
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  payload.solicitudId = solicitud.id
  const inputRef = useRef()
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const funcionarios = useSelector(state => state.app.funcionarios)
  const informeTecnico = {
    'Senadi':   payload.InformeTecnicoSenadi,
    'Maate':    payload.InformeTecnicoMaate,
    'Senescyt': payload.InformeTecnicoSenescyt,
  }[nombreOrganizacion]
  const labelTab2 = ['55000002_Activity_EmitirDictamenTecnicoMaate'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab3 = ['55000002_Activity_EmitirDictamenTecnicoMaate'].includes(id)?'Expediente':'Expediente Modificatoria'
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha: today,
    identificador: solicitud.numeroSolicitud,
    asunto: '',
    pdfGenerado: false,
    serial: '',
    docx: '',
    pdf: '',
    docxLded: '',
    pdfLded: '',

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//
  }
  let myPayload = {
    "55000023_Activity_EmitirInformeSenadiWF15": payload?.Solicitud?.target?.payload,
    '55000023_Activity_EmitirInformeMaateWF15':  payload?.Solicitud?.target?.payload,
  }[instanciaTarea?.tareaCodigoTarea]
  if(!f.isValid(myPayload)) {
    myPayload = payload
  } else {
    myPayload = JSON.parse(myPayload)
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  // const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const dictamenTecnico = {
    'Senadi':   !esContrato?'56015020-1':'56015020-1',
    'Maate':    !esContrato?'56015014-1':'56015014-2',
    // 'Senescyt': '56002003-1',
  }[nombreOrganizacion]
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => { setMytab(newTab) }
  // const pdf = {
  //   'Senadi':   payload?.InformeTecnicoSenadi?.pdf,
  //   'Maate':    payload?.InformeTecnicoMaate?.pdf,
  // }[nombreOrganizacion]
  const subfolder = `dictamen-tecnico-${nombreOrganizacion}`
  const filename = `dictamen-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  // const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const codigoAutorizador = {
    'Maate':    1143,
    'Senadi':   1148,
    'Senescyt': 1139,
  }[nombreOrganizacion]
  const codigoCoordinador = {
    'Maate':    1144,
    'Senadi':   1148,
    'Senescyt': 164,
  }[nombreOrganizacion]
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
          instanciaProceso?.id,
          payload,
          () => setCounter(0)
        )
      )
    }
  }

  // Test firma electronica ---\\
  useEffect(() => {
    if(formValues.firmando && !formValues.firmado) {
      const testFirmas = async () => {
        API.testFirmarPdf(instanciaTarea.id).then((res) => {
          if(res?.resultado === true) {
            API.testDocFirmadoPdf(instanciaTarea.id).then((signedPdf) => {
              if(f.isValidNotEmpty(signedPdf?.rutaDocumento)) {
                const ruta = signedPdf?.rutaDocumento?.split('/')
                const newSignedDoc = ruta[ruta.length - 1]
                const newSubFolder = ruta[ruta.length - 2]
                const fv = {
                  ...formValues,
                  firmando:false,
                  firmado:true,
                  pdfSigned: newSignedDoc,
                  rudaDocumentoFirmado: signedPdf?.rutaDocumento,
                  subfolder: newSubFolder
                }
                setFormValues(fv)
                getOnSave(fv)()
              }
            })
          }
        })
      }
      testFirmas().then(r => r)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.firmando])
  const [, dispatch2] = useReducer(reducer, {firmando: formValues.firmando})
  // Test firma electronica ---//

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <Stack
          direction={'row'}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            background: 'transparent'
          }}>
          <SectionTitle title={'Emitir Dictamen Técnico'} onClick={()=> { dispatch(handleClear()) }} />
          <Typography sx={{m:'10px 10px 0 0', fontSize:'0.4rem'}}>{'id: ' + instanciaTarea.id}</Typography>
        </Stack>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label={labelTab2} value="2" />
            <Tab label={labelTab3} value="3" />
            {(f.isValid(payload?.solicitudOriginal))?<Tab label="Solicitud original" value="4" />:false}
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <Tab label={`Expediente original`} value="5"/> : null
            }
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Informe Técnico'} />
            </Grid>
            <Grid item xs={4} sx={{p:'0 2rem 0 2rem'}}>
              <MyReadOnlyTextField id={'itfecha'}
                                   label={'Fecha'}
                                   icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />}
                                   value={informeTecnico?.fecha} />
            </Grid>
            <Grid item xs={4} style={{padding:'1rem 0 1rem 1rem'}}>
                <MyReadOnlyTextField id={'itidentificador'}
                                     label={'Identificador'}
                                     icon={<AssignmentIndOutlinedIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />}
                                     value={payload?.InformeTecnicoMaate?.serial} />
            </Grid>
            <Grid item xs={4} style={{padding:'1rem 0 1rem 1rem'}}>
              <Stack direction={'row'} justifyContent="flex-end" alignItems="center" sx={{mr:'1.5rem'}}>
                <MyButtonBacan label={'VER PDF'}
                               onClick={() => {
                                 const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe-tecnico-${nombreOrganizacion}/${payload.InformeTecnicoMaate.pdfSigned}`
                                 fetch(url)
                                   .then((res) => { return res.blob(); })
                                   .then((data) => {
                                     const dataPdf = new Blob([data], { type: 'application/pdf' })
                                     const a = document.createElement("a")
                                     a.href = window.URL.createObjectURL(dataPdf)
                                     a.target="_blank"
                                     a.click()
                                   })
                               }}
                               myTip={'Ver resolución'}
                               icon={FileDownloadOutlinedIcon} />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={`${nombreOrganizacion} - Dictamen Técnico`} />
            </Grid>
            <Grid item xs={4}>
              <MyReadOnlyTextField id={'fecha'}
                                   label={'Fecha'}
                                   icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />}
                                   value={formValues.fecha} />
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}
                     justifyContent="space-between"
                     alignItems="center"
                     sx={{mt:'14px'}}
                     spacing={2}>
                <MyReadOnlyTextField id={'identificador'}
                                     label={'Identificador'}
                                     icon={<AssignmentIndOutlinedIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />}
                                     value={formValues.serial} />
              </Stack>
            </Grid>
            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 1rem'}}>
                {
                  formValues.serial === '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'GENERAR SERIAL'}
                                    width={'11rem'}
                                    bgColor1={'rgb(148 193 32)'}
                                    onClick={() => {
                                      const tipo = !esContrato?'DTMAT':'DTMATCPC'
                                      API.secuenciaSet(tipo).then((ser) => {
                                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                          getOnSave({...formValues, serial:serial})()
                                          setFormValues({...formValues, serial:serial})
                                        }
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={''}
                                    width={'3rem'}
                                    onClick={() => {
                                      setFormValues({
                                        ...formValues,
                                        docx: '',
                                        pdf: '',
                                        docxLded: '',
                                        pdfLded: '',

                                        // firma -- \\
                                        pdfSigned:    '',
                                        firmaTareaId: 0,
                                        firmando:     false,
                                        firmado:      false,
                                        // firma -- //
                                      })
                                    }}
                                    disabled={formValues.firmado || formValues.firmando}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx === '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'Generar Informe'}
                                    width={'11rem'}
                                    onClick={() => {
                                      API.fetchPlantila(dictamenTecnico).then(taggeDoc => {
                                        const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, P0102API.mapping({
                                          solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                          payload,
                                          autoridad: autorizador,
                                          elaborar:  perfilUsuario,
                                          revisar:   coordinador,
                                          aprobar:   autorizador,
                                          nombreOrganizacion,
                                          tipo: instanciaProceso.tipoInstanciaProceso?.toLowerCase(),
                                          section: payload[section],
                                          informeTecnico: payload?.InformeTecnicoMaate?.serial,
                                        }))
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                          nombreOrganizacion,
                                        })
                                      })
                                    }}
                                    toolTip={'Generar'}
                                    icon={MiscellaneousServicesIcon} />
                  </ButtonGroup>
                }
                {
                  (formValues.docx !== '') ?
                    <>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })}
                                      toolTip={'Descargar documento en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      toolTip={'Descargar documento en formato pdf'}
                                      width={'5rem'} />
                    </> : false
                }
                {
                  (formValues.docx !== '') ?
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton inputRef={inputRef}
                                          label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          toolTip={'Subir documento modificado en formato docx'}
                                          width={'5rem'}
                                          disabled={formValues.firmado || formValues.firmando} />
                    </ButtonGroup> : false
                }
                {
                  formValues.docx !== '' &&
                  <>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'PDF (firmado)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                        toolTip={'Subir documento firmado o para firmar en formato pdf'}
                                        width={'11rem'}
                                        disabled={formValues.firmando || formValues.firmado || (formValues.docxLded === '')} />
                    {!formValues.firmando &&
                      <MyButtonBacan3 label={'FIRMAR'}
                                      icon={FileDownloadIcon}
                                      onClick={()=> {
                                        API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                          if(res?.length > 0) {
                                            const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                            const firmaTareaId = res[0].firmaTareaId
                                            const fv = {
                                              ...formValues,
                                              firmando:true,
                                              firmado:false,
                                              pdfSigned,
                                              firmaTareaId
                                            }
                                            setFormValues(fv)
                                            dispatch2({type: 'after-firmar', fv})
                                            getOnSave(fv)()
                                          }
                                        })
                                      }}
                                      disabled={formValues.firmado || (formValues.docxLded === '')}
                                      toolTip={'firmar electronicamente el documento'}
                                      width={'9rem'} />
                    }
                    {
                      formValues.firmado &&  <MyButtonBacan3 label={''}
                                                             icon={FileDownloadIcon}
                                                             onClick={() => API.fetchDownloadPDF({
                                                               solicitudId: instanciaProceso?.solicitud?.id,
                                                               subfolder,
                                                               filename: filenamePDFsigned,
                                                             })}
                                                             toolTip={'Descargar dictamen tecnico'}
                                                             width={'2rem'}/>
                    }
                  </>
                }
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={myPayload}/>
        </TabPanel>
        <TabPanel value="3">
          <P0102Expediente payload={payload}/>
        </TabPanel>
        {f.isValid(payload?.solicitudOriginal) ?
          <TabPanel value="4">
            <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
          </TabPanel> : false
        }
        {f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
            <TabPanel value="5">
              <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
            </TabPanel> : null
        }
      </TabContext>

      <CssBaseline/>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} >
              <Stack direction={'row'}
                     spacing={0}
                     justifyContent="space-between"
                     alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={getOnSave()}
                              disabled={counter <= 0} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({"solicitudId":`${instanciaProceso?.solicitud?.id}`})
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              disabled={counter > 0 || formValues.docx === ''|| formValues.pdf === '' || !formValues.firmado} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

export default P01SolicitudDictamenTecnico
