import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {add, format, parse} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import API from "src/features/App/API"
import WF0102API from "src/features/P01Solicitud/API"
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import {f} from 'src/commons/f'
import GLOBALS from 'src/features/App/globals'
import {SectionTitle} from "../../components/SectionTitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {AccountCircle} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Toolbar from "@mui/material/Toolbar";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "./P0102PayloadRO";
import {P0102Expediente} from "./P0102Expediente";
import {MyReadOnlyAreaTextField} from "../../components/MyReadOnlyAreaTextField";

const P02SolicitudElaborarContrato = () => {
  const section = 'ElaborarContrato'
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  // const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:            today,
    serial:           '',
    identificador:    solicitud.numeroSolicitud,
    tiempoVigencia:   '' + payload.Propuesta.plazo + ' meses',
    docx:             '',
    pdf:              '',
    docxLded:         '',
    pdfLded:          '',
    pdfSigned:        '',
  }

  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const dResolucion = parse(slct.payload.Resolucion.fecha,'dd-MMMM-yyyy', new Date(), {locale: es})
  const plazo = Number(slct.payload.Propuesta.plazo)
  const dPlazo = add(dResolucion, {months: plazo})
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `contrato-${nombreOrganizacion}`
  const filename = `contrato-${formValues.identificador}-${nombreOrganizacion}`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  // const filenamePDFsigned = `${filename}-firmado.pdf`
  const inputRef = useRef()
  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const autoridadSenescyt = funcionarios.filter(it => it.idPerfil === 1189)[0]
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={instanciaTarea.tareaCodigoTarea === '55000017_Activity_ElaborarContratoWF0405'?
            'Elaboración de contrato modificatorio de acceso con potencial uso comercial':
            'Elaboración de contrato de acceso con potencial uso comercial'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="Solicitud" value="2" />
              <Tab label="Expediente" value="3" />
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Solicitud ${payload?.solicitudOriginal?.solicitudNumeroSolicitud}`} value="4"/> : null
              }
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente ${payload?.solicitudOriginal?.solicitudNumeroSolicitud}`} value="5"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <>
              <Grid container spacing={1}>
                <Solicitante solicitud={{solicitud: {payload}}} displayContact={false} />
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Resolución'} />
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                      <MyReadOnlyTextField id='identificador'
                                           label={'Identificador'}
                                           value={solicitud?.numeroSolicitud}
                                           icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                      <MyReadOnlyTextField id='fecha'
                                           label={'Fecha'}
                                           value={slct?.payload?.Resolucion?.fecha}
                                           icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                      <MyButtonBacan label={'VER PDF'}
                                     onClick={() => {
                                       const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${solicitud?.id}/resolucion-Senescyt/${slct.payload?.Resolucion?.pdfSigned}`
                                       fetch(url)
                                         .then((res) => { return res.blob(); })
                                         .then((data) => {
                                           const dataPdf = new Blob([data], { type: 'application/pdf' })
                                           const a = document.createElement("a")
                                           a.href = window.URL.createObjectURL(dataPdf)
                                           a.target="_blank"
                                           a.click()
                                         })
                                     }}
                                     myTip={'Ver resolución'}
                                     icon={FileDownloadOutlinedIcon} />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Contrato'} />
                </Grid>
                {f.isValidNotEmpty(payload.RevisarContrato?.observaciones) ?
                  <Grid item xs={12} style={{padding: '0 24px 0 0.5rem'}}>
                    <MyReadOnlyAreaTextField id={'observaciones'}
                                     label={'Observaciones hechas por el investigador'}
                                     value={payload.RevisarContrato?.observaciones}
                                     rows={10}/>
                  </Grid> : null
                }
                <Grid item xs={12} style={{padding:'0 24px 0 0.5rem'}}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                    <MyReadOnlyTextField id='identificador'
                                         label={'Identificador'}
                                         value={solicitud?.numeroSolicitud}
                                         icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyReadOnlyTextField id='fecha'
                                         label={'Fecha de emisión'}
                                         value={format(dResolucion, 'dd-MMM-yyyy', {locale: es})}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyReadOnlyTextField id='plazo'
                                         label={'Vigencia'}
                                         value={`${plazo} meses`}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyReadOnlyTextField id='fechaCaducidad'
                                         label={'Fecha de caducidad'}
                                         value={format(dPlazo, 'dd-MMM-yyyy', {locale: es})}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  </Stack>
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                    {
                      formValues.serial === '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'GENERAR SERIAL'}
                                        width={'11rem'}
                                        bgColor1={'rgb(148 193 32)'}
                                        onClick={() => {
                                          const tipo = 'AUTCPC'
                                          API.secuenciaSet(tipo).then((ser) => {
                                            if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                              const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                              getOnSave({...formValues, serial:serial})()
                                              setFormValues({...formValues, serial:serial})
                                            }
                                          })
                                        }}
                                        icon={RestartAltIcon}/>
                      </ButtonGroup>
                    }
                    {
                      formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={''}
                                        width={'3rem'}
                                        onClick={() => {
                                          setFormValues({
                                            ...formValues,
                                            docx: '',
                                            pdf: '',
                                            docxLded: '',
                                            pdfLded: '',
                                            pdfSigned: '',
                                          })
                                        }}
                                        icon={RestartAltIcon}/>
                      </ButtonGroup>
                    }
                    {
                      formValues.docx === '' && formValues.serial !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'Generar Informe'}
                                        width={'11rem'}
                                        onClick={() => {
                                          API.fetchPlantila('56015025-1').then(taggeDoc => {
                                            const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, WF0102API.mapping({
                                              solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                              payload,
                                              autorizador,
                                              coordinador,
                                              perfilUsuario,
                                              nombreOrganizacion,
                                              autoridadSenescyt,
                                              section: payload[section],
                                            }))
                                            API.genDocxAnPdf({
                                              solicitudId: instanciaProceso?.solicitud?.id,
                                              document: doc,
                                              formValues,
                                              setFormValues,
                                              subfolder,
                                              filename: filenameDOCX,
                                              nombreOrganizacion,
                                            })
                                          })
                                        }}
                                        icon={MiscellaneousServicesIcon} />
                      </ButtonGroup>
                    }
                    {
                      formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'DOCX'}
                                        icon={FileDownloadIcon}
                                        onClick={() => API.fetchDownloadDOCX({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                        })}
                                        width={'5rem'} />
                        <MyButtonBacan3 label={'PDF'}
                                        icon={FileDownloadIcon}
                                        onClick={() => API.fetchDownloadPDF({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                        })}
                                        width={'5rem'} />
                      </ButtonGroup>
                    }
                    {
                      formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyFileUploadButton inputRef={inputRef}
                                            label={'DOCX'}
                                            solicitudId={instanciaProceso?.solicitud?.id}
                                            subfolder={subfolder}
                                            fileName={filenameDOCXuploaded}
                                            afterUpload={() => {
                                              API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                                if(f.isValid(result2.rutaDocumento)) {
                                                  const arr2 = result2.rutaDocumento.split('/')
                                                  setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                                } else {
                                                  setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                                }
                                              })
                                            }}
                                            width={'5rem'} />
                      </ButtonGroup>
                    }
                    {/*{*/}
                    {/*  formValues.docx !== '' &&*/}
                    {/*  <ButtonGroup variant={'contained'}>*/}
                    {/*    <MyFileUploadButton inputRef={inputRef}*/}
                    {/*                        label={'PDF firmado'}*/}
                    {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                    {/*                        subfolder={subfolder}*/}
                    {/*                        fileName={filenamePDFsigned}*/}
                    {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                    {/*                        width={'9rem'} />*/}
                    {/*    {*/}
                    {/*      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}*/}
                    {/*                                                     icon={FileDownloadIcon}*/}
                    {/*                                                     onClick={() => API.fetchDownloadPDF({*/}
                    {/*                                                       solicitudId: instanciaProceso?.solicitud?.id,*/}
                    {/*                                                       subfolder,*/}
                    {/*                                                       filename: filenamePDFsigned,*/}
                    {/*                                                     })}*/}
                    {/*                                                     width={'3rem'}/>*/}
                    {/*    }*/}
                    {/*  </ButtonGroup>*/}
                    {/*}*/}
                  </Stack>
                </Grid>
              </Grid>
            </>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="3">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="4">
                <P0102PayloadRO payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="5">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={getOnSave()}
                                disabled={counter <= 0 } />
                  <MySendButton onSend={() => {
                    const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    "perfilUsuarioSolicitud": Number(slct?.perfilUsuarioId),
                                  })
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                disabled={counter > 0 || formValues.docx === ''}
                                label={'enviar'} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}

export default P02SolicitudElaborarContrato
