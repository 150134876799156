import React, {Fragment, useState} from "react";
import {Button, Card, CardActions, Grid, Stack} from "@mui/material";
import {MyCardHeader} from "../../../components/MyCardHeader";
import {MyCardContent} from "../../../components/MyCardContent";
import {
  fetchPayloadFromProceso,
  handleCrearProceso,
  handleCrearProceso12,
  handleCrearProceso14,
  handleRefreshTareas
} from "../sliceApp";
import {useDispatch, useSelector} from 'react-redux'
import {color02} from 'src/styles/styles'
import {useNavigate} from 'react-router-dom'
import {MyConfirm} from "../../../components/MyConfirm";
import {blue} from "@mui/material/colors";
import {format, parseISO} from 'date-fns'
import {es} from 'date-fns/locale'
import {
  fetchInstanciaTareaInstanciaProceso,
  handleSetBandeja
} from "../sliceApp";
import API from "../API";
import {f} from "../../../commons";

export const WF0710Aprobada = ({aprobada, payload, tareas, suspendida=false}) => {
  const payloadSolicitud = JSON.parse(aprobada.payloadSolicitud)
  const esExsitu = payloadSolicitud?.Solicitud?.tipo === 'EX-SITU'
  const hayHolotipos = esExsitu ? (payloadSolicitud?.Solicitud?.recursosES?.filter(it => it.holotipo === true)?.length > 0) : false
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)

  const [dialogData, setDialogData] = useState({
    title: 'ATENCIÓN',
    message: '[message]',
    openDialog: false,
    okAction: () => {
      alert('IMPLEMENTACIóN NO ASIGNADA . . .')
    },
    cancelAction: () => alert('[cancelAction]'),
    messsageAfterOk: 'Se ejecutó',
    mode: 'OK_CANCEL_AFTEROK',
  })

  const menu0710 = () => {
    const myMenu = [
      {
        key: 'k1',
        description: 'Registrar permiso de importación',   //(WF08)
        action: () => {
          setDialogData({
            ...dialogData,
            message: 'Va a crear una nueva Solicitud de permiso de importación de recursos (WF08)',
            messsageAfterOk: 'Se ha creado una nueva Solicitud de permiso de importación de recursos (WF08)',
            openDialog: true,
            okAction: () => {
              // --- new proposal (same as WF11
              let solicitudAprobada = {
                ...aprobada,
                payloadSolicitud: JSON.parse(aprobada.payloadSolicitud)
              }
              solicitudAprobada = {
                ...solicitudAprobada,
                payloadSolicitud: {
                  ...solicitudAprobada.payloadSolicitud,
                  Solicitud: {
                    ...solicitudAprobada.payloadSolicitud.Solicitud,
                    solicitudAprobada: JSON.parse(solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada)
                  }
                }
              }
              // --- Optimizing ATM
              const wf0102 = {...solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada}
              delete solicitudAprobada.payloadSolicitud.T55000006_Activity_AsignarCaso
              delete solicitudAprobada.payloadSolicitud.Validar
              delete solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada
              solicitudAprobada.payloadSolicitud.wf0102 = wf0102
              solicitudAprobada.payloadSolicitud.TYPE = 'WF10'
              solicitudAprobada.payloadSolicitud = JSON.stringify(solicitudAprobada.payloadSolicitud)
              // console.log('::::: solicitud aprobada :::: ', solicitudAprobada)
              // --- Buildinh new process 55000007 the process
              dispatch(handleCrearProceso(55000007, perfilUsuario?.id, perfilUsuario?.idPerfilUsuario, solicitudAprobada))
              // --- Call new tareas list
              setTimeout(function() {
                dispatch(handleRefreshTareas(perfilUsuario.id))
              }, 1500)
            }
          })
        }
      },
      {
        key: 'k2',
        description: 'Solicitar salida de holotipos',
        action: () => {
          setDialogData({
            ...dialogData,
            message: 'Va a crear una nueva Solicitud de salida de holotipos (WF09)',
            messsageAfterOk: 'Se ha creado una nueva Solicitud de salida de holotipos (WF09)',
            openDialog: true,
            okAction: () => {
              // --- new proposal (same as WF11
              let solicitudAprobada = {
                ...aprobada,
                payloadSolicitud: JSON.parse(aprobada.payloadSolicitud)
              }
              solicitudAprobada = {
                ...solicitudAprobada,
                payloadSolicitud: {
                  ...solicitudAprobada.payloadSolicitud,
                  Solicitud: {
                    ...solicitudAprobada.payloadSolicitud.Solicitud,
                    solicitudAprobada: JSON.parse(solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada)
                  }
                }
              }
              // --- Optimizing ATM
              const wf0102 = {...solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada}
              delete solicitudAprobada.payloadSolicitud.T55000006_Activity_AsignarCaso
              delete solicitudAprobada.payloadSolicitud.Validar
              delete solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada
              solicitudAprobada.payloadSolicitud.wf0102 = wf0102
              solicitudAprobada.payloadSolicitud.TYPE = 'WF10'
              solicitudAprobada.payloadSolicitud = JSON.stringify(solicitudAprobada.payloadSolicitud)
              // console.log('::::: solicitud aprobada :::: ', solicitudAprobada)
              // --- Buildinh new process 55000009 the process
              dispatch(handleCrearProceso(55000009, perfilUsuario?.id, perfilUsuario?.idPerfilUsuario, solicitudAprobada))
              // --- Call new tareas list
              setTimeout(function() {
                dispatch(handleRefreshTareas(perfilUsuario.id))
              }, 1500)
            }
          })
        }
      },
      {
        key: 'k3',
        description: 'Registrar permiso de exportación',
        action: () => {
          setDialogData({
            ...dialogData,
            message: 'Va a crear una nueva Solicitud de permiso de exportación de recursos (WF11)',
            messsageAfterOk: 'Se ha creado una nueva Solicitud de permiso de exportación de recursos (WF11)',
            openDialog: true,
            okAction: () => {
              // exploting ATM
              let solicitudAprobada = {
                ...aprobada,
                payloadSolicitud: JSON.parse(aprobada.payloadSolicitud)
              }
              solicitudAprobada = {
                ...solicitudAprobada,
                payloadSolicitud: {
                  ...solicitudAprobada.payloadSolicitud,
                  Solicitud: {
                    ...solicitudAprobada.payloadSolicitud.Solicitud,
                    solicitudAprobada: JSON.parse(solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada)
                  }
                }
              }
              // Optimizing ATM
              const wf0102 = {...solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada}
              delete solicitudAprobada.payloadSolicitud.T55000006_Activity_AsignarCaso
              delete solicitudAprobada.payloadSolicitud.Validar
              delete solicitudAprobada.payloadSolicitud.Solicitud.solicitudAprobada
              solicitudAprobada.payloadSolicitud.wf0102 = wf0102
              solicitudAprobada.payloadSolicitud.TYPE = 'WF10'
              solicitudAprobada.payloadSolicitud = JSON.stringify(solicitudAprobada.payloadSolicitud)
              // building the process
              dispatch(handleCrearProceso(55000011, perfilUsuario?.id, perfilUsuario?.idPerfilUsuario, solicitudAprobada))
              // Call new tareas list
              setTimeout(function() {
                dispatch(handleRefreshTareas(perfilUsuario.id))
              }, 1500);
            }
          })
        }
      }
    ]

    if(esExsitu) {
      if(!hayHolotipos) {
        return myMenu.filter(it => it.key === 'k1')
      }
      if(hayHolotipos){
        return myMenu.filter(it => it.key === 'k1' || it.key === 'k2')
      }
    } else {
        return myMenu.filter(it => it.key === 'k3')
      }
  }

  // console.log(':::: ', aprobada.solicitudNumeroSolicitud, tareas.filter(it => it.instanciaProcesoNumeroSolicitudPadre === aprobada.solicitudNumeroSolicitud))
  return (
    <Fragment key={`FRAGMENTO-${aprobada.id}`}>
      <Grid key={`WF0710-${aprobada.id}`} item xs={3} sx={{p:'0 0.5rem 0.5rem 0.5rem'}}>
        <Card variant="outlined" sx={{borderWidth: '1px', borderColor: color02}}>
          <MyCardHeader bgColor={color02}
                        color={'white'}
                        cardTitle={aprobada.nombreProceso}
                        menu={suspendida?null:(aprobada.idProceso !== 55000004)?menu0710():null}
                        state={{
                          state: (!aprobada.suspendidaSolicitud ? 'aprobada' : 'suspendida'),
                          tip: !aprobada.suspendidaSolicitud ? 'Aprobada' : 'Suspendida'
                        }} />
          <MyCardContent h1={aprobada.solicitudNumeroSolicitud}
                         h2={payloadSolicitud?.Solicitud?.fecha}
                         content={`${aprobada.nombreProyectoSolicitud}`} />
          <CardActions sx={{backgroundColor: 'white', borderTop: `1px solid ${color02}`}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Button size='small'
                          onClick={() => {
                            dispatch(fetchPayloadFromProceso(aprobada.id))
                            dispatch(handleSetBandeja('entrada'))
                            let goesTo = '/p10ro'
                            switch(aprobada.idProceso) {
                              case 55000004: goesTo = '/p07ro'; break;
                              case 55000022: goesTo = '/p15ro'; break;
                              case 55000005: goesTo = '/p10ro'; break;
                            }
                            setTimeout(() => navigate(goesTo), 1500)
                          }}
                          sx={{width: '40%', color: 'white', backgroundColor: color02}}
                          variant='outlined'>VER</Button>
                </Stack>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
      {
        tareas.filter(it => it.instanciaProcesoNumeroSolicitudPadre === aprobada.solicitudNumeroSolicitud && it.instanciaProcesoSolicitudEstadoSolicitud !== "Denegada").map((tarea, tidx) => {
          const noeditable = (tarea.estadoInstanciaProceso === "En Progreso" || tarea.estadoInstanciaProceso === "Completado") && tarea.estadoInstanciaTarea === "Finalizada"
          const aprobada = tarea.instanciaProcesoSolicitudEstadoSolicitud === "Aprobada"
          let hcolor = noeditable ? blue[500] : blue[800]
          if(tarea.estadoInstanciaProceso === "Completado" && tarea.estadoInstanciaTarea === "Finalizada" && aprobada) {
            hcolor = color02
          }
          const menu09 = (['Completado', 'Finalizado'].includes(tarea.estadoInstanciaProceso))?[
            {
              key: 'WF12',
              description: 'Retorno de holotipos',
              action: () => {
                setDialogData({
                  ...dialogData,
                  message: 'Va a crear una notificación de retorno de holotipos (WF12)',
                  messsageAfterOk: 'Se ha creado una nueva notificación de retorno de holotipos (WF12)',
                  openDialog: true,
                  okAction: () => {
                    API.fetchInstanciaTarea(tarea.id).then(tarea => {
                      API.fetchInstanciaProceso(tarea.idInstanciaProceso).then((proceso) => {
                        proceso.instanciaTareaList = []
                        proceso.solicitud.payload = JSON.parse(proceso.solicitud.payload)
                        const {abuelo, ...atm} = JSON.parse(proceso.solicitud.payload.Solicitud.solicitudAprobada).father
                        delete proceso.solicitud.payload.Solicitud.solicitudAprobada
                        delete proceso.solicitud.payload.T55000010_Activity_AsignarCasoSenescytWF09
                        delete proceso.solicitud.payload.ValidarSolicitud
                        delete proceso.solicitud.payload.ElaborarAutorizacion
                        const solicitudId = proceso.solicitud.payload.solicitudId
                        dispatch(handleCrearProceso12( proceso.id,
                                                       perfilUsuario?.id,
                                                       {Solicitud: {TYPE: 'WF12', wf0102: abuelo, atm, solicitudAprobada: proceso.solicitud}},
                                                       solicitudId ))
                        setTimeout(function() {
                          dispatch(handleRefreshTareas(perfilUsuario.id))
                        },1500)
                      })
                    })
                  }
                })
              }
            },
          ]:null

          const menu11 = (['Completado', 'Finalizado'].includes(tarea.estadoInstanciaProceso))?[
            {
              key: 'WF14',
              description: 'Informar resultados de exportación',
              action: () => {
                setDialogData({
                  ...dialogData,
                  message: 'Va a crear un nuevo informe de resultados de exportación de recursos(WF14)',
                  messsageAfterOk: 'Se ha creado un nuevo informe de resultados de exportación de recursos(WF14)',
                  openDialog: true,
                  okAction: () => {
                    API.fetchInstanciaTarea(tarea.id).then(tarea => {
                      API.fetchInstanciaProceso(tarea.idInstanciaProceso).then((proceso) => {
                        let p = JSON.parse(proceso.solicitud.payload)
                        dispatch(handleCrearProceso14(proceso.id, perfilUsuario?.id,{Solicitud: p.Solicitud},p.solicitudId))
                        setTimeout(function() {
                          dispatch(handleRefreshTareas(perfilUsuario.id))
                        }, 1500)
                      })
                    })
                  }
                })
              }
            },
          ]:null

          let solicitudId = tarea.instanciaProcesoSolicitudNumeroSolicitud.split('-')
          solicitudId = (solicitudId.length>0)?Number(solicitudId[solicitudId.length-1]):solicitudId
          const subtareas = tareas.filter(it => Number(it.instanciaProcesoNumeroSolicitudPadre) === solicitudId)
          // const esWF08 = (tarea?.instanciaProcesoIdProceso === 55000007) && (tarea?.estadoInstanciaProceso === 'Completado') && (tarea?.estadoInstanciaTarea ===  'Finalizada')
          const esWF09 = (tarea?.instanciaProcesoIdProceso === 55000009) && (tarea?.estadoInstanciaProceso === 'Completado') && (tarea?.estadoInstanciaTarea ===  'Finalizada')
          const esWF11 = (tarea?.instanciaProcesoIdProceso === 55000011) && (tarea?.estadoInstanciaProceso === 'Completado') && (tarea?.estadoInstanciaTarea ===  'Finalizada')
          return (
            <Fragment key={`${tarea.instanciaProcesoIdProceso}-${tarea.idTarea}-${tarea.id}`}>
              <Grid key={`${tarea.instanciaProcesoIdProceso}-${tarea.idTarea}-${tarea.id}`} item xs={3} sx={{p:'0 0.5rem 0.5rem 0.5rem'}}>
                <Card variant="outlined" sx={{borderColor: hcolor}} key={tarea.instanciaProcesoSolicitudNumeroSolicitud}>
                  <MyCardHeader bgColor={hcolor}
                                color={'white'}
                                cardTitle={tarea.instanciaProcesoNombreProceso}
                                menu={esWF09?menu09:esWF11?menu11:esWF11?false:false}
                                state={{
                                  state:!noeditable ?'editable':'aprobada',
                                  tip:!noeditable ? 'Editable' : aprobada?'Aprobada':'En evaluación'
                                }} />
                  <MyCardContent h1={tarea.instanciaProcesoSolicitudNumeroSolicitud}
                                 h2={format(parseISO(tarea.fechaHoraInicia), 'dd MMMM yyyy hh:mm', {locale: es})}
                                 content={tarea.instanciaProcesoSolicitudNombreProyecto} />
                  <CardActions sx={{backgroundColor: 'white', borderTop: `1px solid ${hcolor}`}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                          <Button size="small"
                                  sx={{ width: '40%', color: 'white', backgroundColor: `${hcolor}`}}
                                  onClick={() => {
                                    if (!noeditable) {
                                      dispatch(fetchInstanciaTareaInstanciaProceso(tarea.id))
                                      dispatch(handleSetBandeja('entrada'))
                                    }
                                    else {
                                      // console.log('> > > > > > solicitudId :: ', tarea.instanciaProcesoIdProceso + ' ' +tarea.instanciaProcesoNombreProceso)
                                      dispatch(fetchPayloadFromProceso(tarea.idInstanciaProceso))
                                      dispatch(handleSetBandeja('entrada'))
                                      let goesTo = ''
                                      switch(tarea.instanciaProcesoIdProceso) {
                                        case 55000007: goesTo = '/permisoImportacionRO'; break;
                                        case 55000009: goesTo = '/holotiposro'; break;
                                        case 55000011: goesTo = '/permisoExportacionRO'; break;
                                        default: goesTo = ''
                                      }
                                      if(goesTo !== '') {
                                        setTimeout(() => navigate(goesTo), 1500)
                                      }
                                      else {
                                        console.log('error... sin ruta')
                                      }
                                    }
                                  }}
                                  variant={!noeditable ? 'contained' : 'text'}>
                            {!noeditable ? 'Editar--' : 'Ver'}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
              {
                subtareas.map((mtarea, midx) => {
                  const noeditable = mtarea.estadoInstanciaTarea === "Finalizada"
                  const hcolor = noeditable?blue[500]:blue[800]
                  let proceso = {
                    'Permiso':   'Solicitud de Permiso de investigación',
                    'Contrato':  'Solicitud de Contrato de acceso con potencial uso comercial',
                    'Comercial': 'Solicitud de Contrato de acceso con fines comerciales',
                  }[mtarea.tipoInstanciaProceso]

                  if(!f.isValid(proceso)) {
                    proceso = mtarea.instanciaProcesoNombreProceso
                  }
                  // const lcolor = noeditable?blue[300]:blue[600]
                  // console.log(':: mtarea :: ',mtarea.idInstanciaProceso)
                  return (
                    <Grid item key={`wf0101iniciadas-${midx}`} xs={2} sm={6} md={4} lg={3}>
                      <Card variant="outlined" sx={{borderColor: hcolor}}>
                        <MyCardHeader bgColor={hcolor}
                                      color={'white'}
                                      cardTitle={mtarea.instanciaProcesoNombreProceso}
                                      menu={[]}
                                      state={{
                                        state:noeditable? 'noEditable' : 'editable',
                                        tip: noeditable ? 'En evaluación' : 'En elaboración',
                                      }} />
                        <MyCardContent h1={mtarea.instanciaProcesoSolicitudNumeroSolicitud}
                                       h2={format(parseISO(mtarea.fechaHoraInicia),'dd MMMM yyyy hh:mm', {locale: es})}
                                       content={mtarea?.instanciaProcesoSolicitudNombreProyecto} />
                        <CardActions sx={{backgroundColor: 'white', borderTop:`1px solid ${hcolor}`}}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Button size='small'
                                        onClick={() => {
                                          // dispatch(fetchInstanciaTareaInstanciaProceso(mtarea.id))
                                          dispatch(fetchPayloadFromProceso(mtarea.idInstanciaProceso))
                                          dispatch(handleSetBandeja('entrada'))
                                          setTimeout(() => navigate('/p12ro'), 1500)
                                        }}
                                        sx={{width:'40%', color:('white'), borderColor:hcolor}}
                                        variant='contained'>ver</Button>
                                {
                                  !noeditable?
                                    <Button size="small"
                                            sx={{width:'40%', color:(!noeditable?'white':hcolor), borderColor:hcolor}}
                                            onClick={() => {
                                              if(!noeditable) {
                                                dispatch(fetchInstanciaTareaInstanciaProceso(mtarea.id))
                                                dispatch(handleSetBandeja('entrada'))
                                              }
                                            }}
                                            variant={!noeditable?'contained':'text'}>
                                      {(mtarea.tareaCodigoTarea !== "55000014_Activity_RevisarContratoWF0102")?'Editar':'Revisar'}
                                    </Button>:null
                                }
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                })
              }
            </Fragment>
          )
        })
      }
      <MyConfirm dialogData={dialogData} setDialogData={setDialogData} />
    </Fragment>
  )
}
