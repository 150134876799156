import React, {useEffect, useState} from 'react'
import { useSelector} from 'react-redux'
import {
  Box,
  Grid,
} from '@mui/material'

import {dialog} from 'src/styles/styles'
import {rulesFor} from 'src/features/P01Solicitud'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyMultipleSelect} from 'src/components/MyMultipleSelect'
import {MyTableRecursos} from 'src/components/MyTableRecursos'
import {f} from 'src/commons/f'
import {MyTableMuestras} from 'src/components/MyTableMuestras'
import {muestras} from 'src/features/P01Solicitud/CONF'
import {MyTypography} from "../../../components/MyTypography";

/**
 * Función que maneja la sección "RecursosInSitu" correspondiente a recursos IN-SITU de un formulario. Administra
 * los valores de los formularios y las actualizaciones en función de las interacciones del usuario y los cambios
 * de estado.
 *
 * @param {Object} payload - La estructura que contiene todos los elementos del formulario
 * @param {Function} setMainFormValues - Transfiere los valores de los controles a la estructura principal (mainFormValues)
 * @param {Function} incrementCounter - Función que incrementa el contador
 * @param {Object} formErrors - Objeto que contiene los errores para cada elemento del formulario
 */
const RecursosInSitu = ({payload,setMainFormValues,incrementCounter, formErrors}) => {
  const section = 'RecursosInSitu'
  const provincias = useSelector(state => state.app.provincias)
  const bosquesProtectores = useSelector(state => state.app.bosques)
  const areasProtegidas  = useSelector(state => state.app.areasProtegidas)
  const laboratorios = useSelector(state => state.app.laboratorios)
  const bandeja = useSelector(state => state.app.bandeja)
  const canEdit = bandeja === 'entrada'
  const RULES = rulesFor(section)
  const emptyPayload = RULES.emptyPayload()
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  useEffect(() => {
    setMainFormValues(prev => ({...prev, [section]:formValues}))
    incrementCounter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues])
  const handlesChange= RULES.handlesChange()
  const [recursoSelected, setRecursoSelected] = useState({})
  const [areasProtegidasData, setAreasProtegidasData] = useState([])
  const [bosquesProtectoresData, setBosquesProtectoresData] = useState([])

  useEffect(() => {
    setAreasProtegidasData(areasProtegidas.filter(it => formValues.provincias.includes(it.provincia)).map(it => it.nombre))
    setBosquesProtectoresData(bosquesProtectores.filter(it => formValues.provincias.includes(it.provincia)).map(it => it.nombre))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.provincias])
  const oldRecursos = payload.solicitudOriginal?.payloadSolicitud?.RecursosInSitu?.recursos?.map(it => it.taxonid) ?? []
  const oldMuestras = f.isValid(recursoSelected?.id) ? payload.solicitudOriginal?.payloadSolicitud?.RecursosInSitu?.muestras?.filter(it => it.taxonid === recursoSelected?.id):[]
  const origLabs = payload.solicitudOriginal?.payloadSolicitud?.RecursosInSitu?.laboratorios ?? []
  useEffect(() => {
    if(origLabs.length > 0) {
      const labs = [...formValues.laboratorios]
      origLabs.forEach(it => {
        if(!labs.includes(it)) {
          labs.push(it)
          setFormValues({...formValues, laboratorios: labs})
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.laboratorios])
  const origAreas = payload.solicitudOriginal?.payloadSolicitud?.RecursosInSitu?.areasProtegidas ?? []
  useEffect(() => {
    if(origAreas.length > 0) {
      const areasProtegidas = [...formValues.areasProtegidas]
      origAreas.forEach(it => {
        if(!areasProtegidas.includes(it)) {
          areasProtegidas.push(it)
          setFormValues({...formValues, areasProtegidas})
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.areasProtegidas])
  const origProvs = payload.solicitudOriginal?.payloadSolicitud?.RecursosInSitu?.provincias ?? []
  useEffect(() => {
    if(origProvs.length > 0) {
      const provincias = [...formValues.provincias]
      origProvs.forEach(it => {
        if(!provincias.includes(it)) {
          provincias.push(it)
          setFormValues({...formValues, provincias})
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.provincias])
  const origBosques = payload.solicitudOriginal?.payloadSolicitud?.RecursosInSitu?.bosquesProtectores ?? []
  useEffect(() => {
    if(origBosques.length > 0) {
      const bosquesProtectores = [...formValues.bosquesProtectores]
      origBosques.forEach(it => {
        if(!bosquesProtectores.includes(it)) {
          bosquesProtectores.push(it)
          setFormValues({...formValues, bosquesProtectores})
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.bosquesProtectores])

  return(
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Recursos a ser recolectados (in situ)'} bold={true}/>
        </Grid>
        <Grid item xs={12}>
          <MyTableRecursos id={'recursos'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           muestras={formValues.muestras}
                           columnName={'Recursos'}
                           canEdit={canEdit}
                           mode={'in-situ'}
                           oldRecursos={oldRecursos}
                           addItems={(items) => {
                             const field = 'recursos'
                             items.forEach(it => {
                               it['id'] = it.taxonid
                               it['scientificname'] = f.isValid(it.scientificname)?it.scientificname:''
                               it['redList'] = f.isValid(it.nationalredlist)?it.nationalredlist:''
                               it['taxonomicHierarchy'] = f.isValid(it.taxonomicHierarchy)?it.taxonomicHierarchy:''
                               it['categoriaEspecial'] = f.isValid(it.descripcion)?it.descripcion:''
                               it['artificialGroup'] = f.isValid(it.artificialgroup)?it.artificialgroup:''
                               it['cites'] = f.isValid(it.cites)?it.cites:''
                             })
                             const newSet = [ ...formValues[field]]
                             items.forEach(it => {
                               const idx = newSet.findIndex(ns => ns.taxonid === it.taxonid)
                               if(idx < 0) {
                                 newSet.push(it)
                               }
                             })
                             setFormValues({...formValues, [field]:newSet})
                           }}
                           addItems2={(items) => {
                             const field = 'recursos'
                             items.forEach(it => {
                               it['id'] = it.taxonid
                               it['redList'] = f.isValid(it.nationalredlist)?it.nationalredlist:''
                               it['cites'] = f.isValid(it.cites)?it.cites:''
                               it['scientificname'] = f.isValid(it.scientificname)?it.scientificname:''
                               it['taxonomicHierarchy'] = f.isValid(it.taxonomicHierarchy)?it.taxonomicHierarchy:''
                               it['artificialGroup'] = f.isValid(it.artificialgroup)?it.artificialgroup:''
                               it['categoriaEspecial'] = f.isValid(it.descripcion)?it.descripcion:''
                             })
                             const newSet = [ ...formValues[field]]
                             items.forEach(it => {
                               const idx = newSet.findIndex(ns => ns.taxonid === it.taxonid)
                               if(idx < 0) {
                                 newSet.push(it)
                               }
                             })
                             setFormValues({...formValues, [field]:newSet})
                           }}
                           setRecursoSelected={setRecursoSelected}
                           canDeleteRow={canEdit} />
        </Grid>
        {
          f.isValidNotEmpty(formErrors[section]['recursos']) ?
            <Grid item xs={12}><MyTypography>{(formErrors[section]['recursos'])}</MyTypography></Grid>:null
        }
        {
          f.isValid(recursoSelected.scientificname)?
            <>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Muestras y submuestras'} />
              </Grid>
              <Grid item xs={12} >
                {recursoSelected.scientificname}
              </Grid>
              <Grid item xs={12} >
                <MyTableMuestras id={'muestras'}
                                 selected={recursoSelected}
                                 formValues={formValues}
                                 setFormValues={setFormValues}
                                 canEdit={canEdit}
                                 muestras={muestras}
                                 oldies={oldMuestras}
                                 addItem={() => {
                                   let key = 1
                                   if(formValues['muestras'].length > 0) {
                                     const keys = formValues['muestras'].map(it => Number(it.id.split('-')[1]))
                                     key = keys.reduce((a, b) => Math.max(a, b), -Infinity)+1
                                   }
                                   const newMuestra = {
                                     id: `${recursoSelected.taxonid}-${key}`,
                                     taxonid: recursoSelected.taxonid,
                                     tipo: '',
                                     submuestra: '',
                                     loteotro: '',
                                     descripcion: '',
                                     cantidadSolicitada: 0,
                                     cantidadAutorizada: 0,
                                     saldoDRM: 0,
                                     saldorATM: 0,
                                   }
                                   setFormValues({...formValues,muestras:[...formValues['muestras'],newMuestra]})
                                 }} />
              </Grid>
            </>:null
        }
        {
          f.isValidNotEmpty(formErrors[section]['muestras']) ?
            formErrors[section]['muestras'].split('.').map((it, idx) => {
              return (
                <Grid key={idx} item xs={4}>
                  <MyTypography key={idx}>{it}</MyTypography>
                </Grid>
              )
            })
            :null
        }
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Origen de los recursos'} />
        </Grid>
        <Grid item xs={6}>
          <MyMultipleSelect id={'provincias'}
                            label={'Provincias *'}
                            data={provincias}
                            setFormValues={setFormValues}
                            formValues={formValues}
                            canEdit={canEdit}
                            handleChange={handlesChange['provincias']}/>
        </Grid>
        {
          f.isValidNotEmpty(formErrors[section]['provincias']) ?
            <Grid item xs={12}><MyTypography>{(formErrors[section]['provincias'])}</MyTypography></Grid>:null
        }
        <Grid container>
          <Grid item xs={6}>
            <MyMultipleSelect id={'areasProtegidas'}
                              label={'Áreas protegidas'}
                              data={areasProtegidasData}
                              setFormValues={setFormValues}
                              formValues={formValues}
                              canEdit={canEdit}
                              handleChange={handlesChange['areasProtegidas']}/>
          </Grid>
          <Grid item xs={6}>
            <MyMultipleSelect id={'bosquesProtectores'}
                              label={'Bosques Protectores'}
                              data={bosquesProtectoresData}
                              setFormValues={setFormValues}
                              formValues={formValues}
                              canEdit={canEdit}
                              handleChange={handlesChange['areasProtegidas']} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Destino de los recursos'} />
        </Grid>
        <Grid item xs={12} >
          <MyMultipleSelect id={'laboratorios'}
                            label={'Institución en la que se desarrollará la fase de laboratorio *'}
                            data={[...laboratorios].sort()}
                            setFormValues={setFormValues}
                            formValues={formValues}
                            canEdit={canEdit}
                            handleChange={handlesChange['areasProtegidas']} />
        </Grid>
        {
          f.isValidNotEmpty(formErrors[section]['laboratorios']) ?
            <Grid item xs={12}><MyTypography>{(formErrors[section]['laboratorios'])}</MyTypography></Grid>:null
        }
      </Grid>
    </Box>
  )
}

export default RecursosInSitu
