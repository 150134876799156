import React, {useEffect, useState} from 'react'
import {
  Card, CardActions,
  Grid, Paper,
  Stack,
} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import Box from '@mui/material/Box'
import {
  fetchInstanciaProcesoRO,
  fetchInstanciaTareaInstanciaProceso,
  handleRefreshTareas,
  handleDuplicarProceso,
  handleSetBandeja, fetchPayloadFromProceso,
} from 'src/features/App/sliceApp'
import {f} from 'src/commons/f'
import API from 'src/features/App/API'
import {useNavigate} from 'react-router-dom'
import {blue} from '@mui/material/colors'
import {MySubtitle2} from 'src/components/MySubtitle'
import {format, parseISO} from 'date-fns'
import {es} from 'date-fns/locale'
import Button from "@mui/material/Button";
import {Aprobada} from 'src/features/App/subcomponents/Aprobada'
import {MyCardHeader} from "../../../components/MyCardHeader"
import {MyCardContent} from "../../../components/MyCardContent"
import {MyConfirm} from "../../../components/MyConfirm"

export const TareasInvestigador = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const aprobadas = useSelector(state => state.app.solicitudesAprobadas)
  const noAprobadas = useSelector(state => state.app.solicitudesNoAprobadas)
  const instanciasTarea = useSelector(state => state.app.instanciasTarea)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  // console.log(' . . . . . . . PU: ', perfilUsuario)
  // console.log(' . . . . . . .ITS: ', [...instanciasTarea]?.sort(function(it1, it2) { return it1.id - it2.id;}))
  // console.log(' . .  . APROBADAS: ', [...aprobadas]?.sort(function(it1, it2) { return it1.id - it2.id;}))
  // console.log(' . . NO APROBADAS: ', noAprobadas)
  // console.log(' . . . APROBADAS: ', aprobadas.filter(it => it.idProceso ===55000005))
  // const solicitudIdCreated = useSelector(state => state.app.solicitudIdCreated)
  // console.log('. . . . solicitudIdCreated :: ', solicitudIdCreated)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const iniciadas = instanciasTarea.filter(it => it.estadoInstanciaTarea === 'Iniciada' || (it.estadoInstanciaProceso ==="En Progreso" && it.estadoInstanciaTarea==="Finalizada"))
  let wf010203iniciadas = iniciadas.filter(it => [
    'Process_WF5_Usuario',
    'Process_WF0102_Usuario',
    'Process_WF0102_Usuario',
    'Process_WF0405_Usuario',
    'Process_WF03_Usuario',
    'Process_WF03_VUV',
    // 'Process_WF12',
  ].includes(it.instanciaProcesoProcesoCodigoProceso))

  React.useEffect(() => {
    if(instanciaTarea?.id !== null) {
      const route = API.getRoute(instanciaTarea)
      if(f.isValid(route)) navigate(route)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[instanciaTarea])

  useEffect(() => {window.scrollTo(0, 0)}, [])
  const [dialogData, setDialogData] = useState({
    title: 'ATENCIÓN',
    message: '[message]',
    openDialog: false,
    okAction: () => {},
    cancelAction: () => alert('[cancelAction]'),
    messsageAfterOk: 'Se ejecutó',
    mode:'OK_CANCEL_AFTEROK'
  })

  return (
    <Box sx={{p:'1rem'}}>
      {wf010203iniciadas.length > 0 ?
        <MySubtitle2 subtitle={'Solicitudes Iniciadas y Proceso de Aprobación'} />:
        <MySubtitle2 subtitle={'NO HAY Solicitudes en proceso de Aprobación'}/>
      }
      <Grid container spacing={1}>
        {
          wf010203iniciadas?.sort((t1, t2) => (t1.fechaHoraInicia > t2.fechaHoraInicia) ?-1:1)?.map((mtarea, midx) => {
            const noeditable = mtarea.estadoInstanciaProceso === "En Progreso" && mtarea.estadoInstanciaTarea === "Finalizada"
            const hcolor = noeditable?blue[500]:blue[800]
            let proceso = ''

            switch(mtarea.tipoInstanciaProceso) {
              case 'Permiso': proceso = `${((mtarea.instanciaProcesoIdProceso === 55000016)?'Modificación ':'')}Solicitud de Permiso de investigación`; break ;
              case 'Contrato': proceso = `${((mtarea.instanciaProcesoIdProceso === 55000016)?'Modificación ':'')}Solicitud de Contrato de acceso con potencial uso comercial`; break;
              case 'Comercial': proceso = 'Solicitud de Contrato de acceso con fines comerciales'; break;
              default : proceso = '-default-';
            }

            if(!f.isValid(proceso)) { proceso = mtarea.instanciaProcesoNombreProceso }

            return (
              <Grid item key={`wf0101iniciadas-${midx}`} xs={2} sm={6} md={4} lg={3}>
                <Card variant="outlined" sx={{borderColor: hcolor}}>
                  <MyCardHeader bgColor={hcolor}
                                color={'white'}
                                cardTitle={proceso}
                                menu={[]}
                                state={{
                                  state:noeditable? 'noEditable' : 'editable',
                                  tip: noeditable ? 'En evaluación' : 'En elaboración',
                                }} />
                  <MyCardContent h1={mtarea.instanciaProcesoSolicitudNumeroSolicitud}
                                 h2={format(parseISO(mtarea.fechaHoraInicia),'dd MMMM yyyy hh:mm', {locale: es})}
                                 content={mtarea?.instanciaProcesoSolicitudNombreProyecto} />
                  <CardActions sx={{backgroundColor: 'white', borderTop:`1px solid ${hcolor}`}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                          <Button size='small'
                                  onClick={() => {
                                    dispatch(fetchInstanciaProcesoRO(mtarea.idInstanciaProceso))
                                    let goesTo = ''
                                    switch(mtarea.instanciaProcesoIdProceso) {
                                      case 55000014: goesTo = '/P0102RO'; break;
                                      default: goesTo = ''
                                    }
                                    if(goesTo !== '') {
                                      setTimeout(() => navigate(goesTo), 1500)
                                    }
                                    else {
                                      console.log('error... sin ruta')
                                    }
                                  }}
                                  sx={{width:'40%', color:('white'), borderColor:hcolor}}
                                  variant='contained'>ver</Button>
                          {
                            !noeditable?
                              <Button size="small"
                                      sx={{width:'40%', color:(!noeditable?'white':hcolor), borderColor:hcolor}}
                                      onClick={() => {
                                        if(!noeditable) {
                                          dispatch(fetchInstanciaTareaInstanciaProceso(mtarea.id))
                                          dispatch(handleSetBandeja('entrada'))
                                        }
                                      }}
                                      variant={!noeditable?'contained':'text'}>
                                {(!["55000014_Activity_RevisarContratoWF0102","55000031_Activity_EmitirResolucion_WF03"].includes(mtarea.tareaCodigoTarea))?'Editar':'Revisar'}
                              </Button>:null
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            )
          })
        }
        <>
          {
            aprobadas?.filter(it => f.isValid(it.nombreProyectoSolicitud) && [55000016,55000017,55000014, 55000013, 55000020, 55000030].includes(it.idProceso))?
              <Grid item xs={12} >
                <MySubtitle2 subtitle={'Solicitudes Aprobadas'} />
              </Grid>:null
          }
          {
            aprobadas?.filter(it => f.isValid(it.nombreProyectoSolicitud) && [55000016,55000017,55000014, 55000013, 55000020, 55000030].includes(it.idProceso) )
              .map((aprobada, midx) => {
                return (
                  <Paper key={midx} elevation={2} sx={{width:'100%', p:'0.5rem 0 0 0', m:'1rem 0 1rem 0'}}>
                    <Grid container key={`aprobada-${midx}`}>
                      <Aprobada aprobada={aprobada}
                                tareas={instanciasTarea.filter(it => [
                                  55000004, 55000005, 55000007, 55000011,
                                  55000016, 55000017, 55000009, 55000025,
                                  55000024, 55000026, 55000027, 55000013,
                                  55000020, 55000022, 56031046 ].includes(it.instanciaProcesoIdProceso))}
                                WF0710Aprobadas={aprobadas?.filter(it => [
                                  55000004, 55000005, 55000013, 55000020,
                                  55000022, 56031046
                                ].includes(it.idProceso))} />
                    </Grid>
                  </Paper>
                )
              })
          }
        </>
        {
          noAprobadas?.filter(it => f.isValid(it.nombreProyectoSolicitud)).length > 0?
            <Grid item xs={12} >
              <MySubtitle2 subtitle={'Solicitudes no aprobadas'} />
            </Grid> :null
        }
        {
          noAprobadas?.filter(it => f.isValid(it.nombreProyectoSolicitud))?.
            sort((p1,p2) => (p1.id < p2.id)?1:-1)?.map((noAprobada, midx) => {
              const nombreProceso = {
                'Permiso':'Solicitud de Permiso de investigación',
                'Contrato':'Solicitud de Contrato de acceso con potencial uso comercial',
              }[noAprobada.tipoInstanciaProceso] ?? noAprobada.nombreProceso
              return (
                <Grid key={`aprobada-${midx}`} item xs={2} sm={6} md={4} lg={3} >
                  <Card variant='outlined' sx={{borderWidth: '1px', borderColor: '#DF4075'}}>
                    <MyCardHeader bgColor={'#DF4075'}
                                  color={'white'}
                                  cardTitle={nombreProceso}
                                  menu={[]}
                                  state={{ state:'noAprobabada', tip: 'No aprobada' }} />
                    <MyCardContent h1={noAprobada.solicitudNumeroSolicitud}
                                   h2={f.isValid(noAprobada?.fechaHoraInicia)?format(parseISO(noAprobada?.fechaHoraInicia),'dd MMMM yyyy hh:mm', {locale: es}):''}
                                   content={noAprobada.nombreProyectoSolicitud} />
                    <CardActions sx={{backgroundColor: 'white', borderTop:`1px solid ${'#DF4075'}`}}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            <Button size='small'
                                    onClick={() => {
                                      const timeOutMS = 1500
                                      switch(noAprobada.idProceso) {
                                        case 55000007:
                                          dispatch(fetchPayloadFromProceso(noAprobada.id))
                                          setTimeout(() => navigate('/permisoImportacionRO'), timeOutMS)
                                          break;
                                        case 55000009:
                                          dispatch(fetchPayloadFromProceso(noAprobada.id))
                                          setTimeout(() => navigate('/holotiposro'), timeOutMS)
                                          break;
                                        case 55000005:
                                          dispatch(fetchPayloadFromProceso(noAprobada.id))
                                          setTimeout(() => navigate('/atmro'), timeOutMS)
                                          break;
                                        default:
                                          dispatch(fetchInstanciaProcesoRO(noAprobada.id))
                                          setTimeout(() => navigate('/P0102RO'), timeOutMS)
                                          break;
                                      }
                                    }}
                                    sx={{width:'40%', bgcolor:'#DF4075', color:('white'), borderColor:'#DF4075'}}
                                    variant='contained'>ver</Button>
                            {[55000015, 55000002, 55000001, 55000014].includes(noAprobada.idProceso) &&
                              <Button size="small"
                                      sx={{width:'40%', bgcolor:'#DF4075', color:('white'), borderColor:'#DF4075'}}
                                      onClick={() => {
                                        setDialogData({
                                          ...dialogData,
                                          message: 'Va a duplicar un proceso',
                                          messsageAfterOk: 'Se ha creado una nueva instancia de flujo de proceso para la solicitud con identificador ',
                                          openDialog: true,
                                          okAction: () => {
                                            dispatch(handleDuplicarProceso(noAprobada.idProceso, perfilUsuario.id, noAprobada))
                                            setTimeout(function(){
                                              dispatch(handleRefreshTareas(perfilUsuario.id))
                                            }, 1500)
                                          }
                                        })
                                      }}
                                      variant={'contained'}>
                                {'DUPLICAR'}
                              </Button>
                            }
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              )
            })
        }
      </Grid>
      <MyConfirm dialogData={dialogData} setDialogData={setDialogData} />
    </Box>
  )
}
