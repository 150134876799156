import React from "react";
import Snackbar from "@mui/material/Snackbar";
import {useDispatch, useSelector} from "react-redux";
import {setMessage} from "./sliceApp";
import {Alert} from "@mui/material";
import {f} from "../../commons";

export const MyAlert = () => {
  const dispatch = useDispatch();
  const msg = useSelector(state => state.app.message) ;
  const open = msg.showMessage ;//|| f.isValidNotEmpty(msg) ;
  const message = msg.message ;
  const severity = msg.severity ; // severity: "warning", "succes", "info", "error"

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setMessage({message:null}));
  };

  return (
    <Snackbar open={open && f.isValidNotEmpty(message)}
              autoHideDuration={5000}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
        {message}
      </Alert>
    </Snackbar>
  )
}

