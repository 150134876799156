import React, {Fragment, useEffect, useReducer, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import P0102API from './API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {
  handCompletarTareaCoordinador,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {MyGobackButton, MySaveButton} from 'src/components/MyCommonButtons'
import {MySubtitle} from 'src/components/MySubtitle'
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import {PageOrientation} from "docx";
import GLOBALS from 'src/features/App/globals'
import {P0102Expediente} from "./P0102Expediente";

const reducer = (state, action) => {
  switch (action.type) {
    case "after-firmar":
      return {
        ...state,
        firmando: action.fv.firmando,
      }
    default:
    throw Error('Unknown action: ' + action.type)
  }
}

const P01InformeTecnicoSenescyt = () => {
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const section = `InformeTecnico${perfilUsuario?.perfil?.organizacion?.nombreOrganizacion}`
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const solicitud = {...instanciaProceso.solicitud}
  let payload = JSON.parse(!!instanciaProceso?.solicitud?.payload?instanciaProceso?.solicitud?.payload:'{}')
  payload.solicitudId = solicitud.id
  const funcionarios = useSelector(state => state.app.funcionarios)

  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:         today,
    serial:        '',
    identificador: solicitud.numeroSolicitud,
    docxGenerado:  false,
    docx:          '',
    pdf:           '',
    docxLded:      '',
    pdfLded:       '',

    // firma electronica ---\\
    pdfSigned:     '',
    firmando:      false,
    firmaTareaId:  0,
    firmado:       false,
    // firma electronica ---//
  }
  let myPayload = {
    "55000023_Activity_ElaborarInformeSenadiWF15": payload?.Solicitud?.target?.payload,
  }[instanciaTarea?.tareaCodigoTarea]

  if(!f.isValid(myPayload)) {
    myPayload = payload
  } else {
    myPayload = JSON.parse(myPayload)
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const getOnSave = (setCounter, fv=null) => {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0)))
    };
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const informeTecnico = !esContrato?'56015003-1':'56015003-2'

  const autoridad = funcionarios.filter(it => it.idPerfil === 1189)[0]
  const revisa = funcionarios.filter(it => it.idPerfil === 1141)[0]
  const aprueba = funcionarios.filter(it => it.idPerfil === 1139)[0]

  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  const toBPM = {
    "criterioTecnicoSenescyt": false,
    "informeCompletoSenescyt": true,
    "ampliacionInformacionSenescyt": false,
  }

  const formTitle = 'Informe Técnico'
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => { setMytab(newTab) }
  const subfolder = `informe-tecnico-${nombreOrganizacion}`
  const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDF = `${filename}.pdf`
  let filenamePDFsigned = `${filename}-firmado.pdf`
  const labelTab2 = 'Solicitud'
  const labelTab3 = 'Expediente'

  // Test firma electronica ---\\
  useEffect(() => {
    if(formValues.firmando && !formValues.firmado) {
      const testFirmas = async () => {
        API.testFirmarPdf(instanciaTarea.id).then((res) => {
          if(res?.resultado === true) {
            API.testDocFirmadoPdf(instanciaTarea.id).then((signedPdf) => {
              if(f.isValidNotEmpty(signedPdf?.rutaDocumento)) {
                const ruta = signedPdf?.rutaDocumento?.split('/')
                const newSignedDoc = ruta[ruta.length - 1]
                const newSubFolder = ruta[ruta.length - 2]
                const fv = {
                  ...formValues,
                  firmando:false,
                  firmado:true,
                  pdfSigned: newSignedDoc,
                  rudaDocumentoFirmado: signedPdf?.rutaDocumento,
                  subfolder: newSubFolder
                }
                setFormValues(fv)
                getOnSave(setCounter,fv)()
              }
            })
          } else {
            console.log(`${instanciaTarea.id} aún no está firmado . . . `)
          }
        })
      }
      testFirmas().then(r => r)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.firmando])
  const [, dispatch2] = useReducer(reducer, {firmando: formValues.firmando})
  // Test firma electronica ---//

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed' color='primary' elevation={0} sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <Stack
          direction={'row'}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            background: 'transparent'
          }}>
          <SectionTitle title={formTitle} />
          <Typography sx={{m:'10px 10px 0 0', fontSize:'0.4rem'}}>{'id: ' + instanciaTarea.id}</Typography>
        </Stack>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label={labelTab2} value="2" />
            <Tab label={labelTab3} value="3" />
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <Tab label={`Solicitud original`} value="4"/> : null
            }
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <Tab label={`Expediente original`} value="5"/> : null
            }
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Informe'} />
            </Grid>
            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 0'}}>
                {
                  formValues.serial === ''&&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'GENERAR SERIAL'}
                                    width={'11rem'}
                                    bgColor1={'rgb(148 193 32)'}
                                    onClick={() => {
                                      // const tipo = instanciaProceso.tipoInstanciaProceso === 'Contrato'?'CAPC':'PIC'
                                      const tipo = 'ITSYT'
                                      API.secuenciaSet(tipo).then((ser) => {
                                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                          getOnSave(setCounter, {...formValues, serial})()
                                          setFormValues({...formValues, serial})
                                          setCounter(0)
                                        }
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={''}
                                    width={'3rem'}
                                    onClick={() => {
                                      setFormValues({
                                        ...formValues,
                                        docx:     '',
                                        pdf:      '',
                                        docxLded: '',
                                        pdfLded:  '',

                                        // firma -- \\
                                        firmaTareaId: 0,
                                        pdfSigned:    '',
                                        firmando:     false,
                                        firmado:      false,
                                        // firma -- //
                                      })
                                    }}
                                    disabled={formValues.firmado || formValues.firmando}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>

                }
                {
                  formValues.docx === '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'Generar Informe'}
                                    width={'11rem'}
                                    onClick={() => {
                                      API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                        const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, P0102API.mapping({
                                          solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                          payload,

                                          autoridad: autoridad,
                                          elaborar:  perfilUsuario,
                                          revisar:   revisa,
                                          aprobar:   aprueba,

                                          nombreOrganizacion,
                                          section: payload[section]
                                        }), PageOrientation.PORTRAIT, instanciaProceso?.solicitud?.nombreProyecto)
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                          nombreOrganizacion,
                                        })
                                      })
                                    }}
                                    icon={MiscellaneousServicesIcon} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => {
                                      API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })
                                    }}
                                    toolTip={'Descargar informe técnico en formato docx'}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => {
                                      API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })
                                    }}
                                    toolTip={'Descargar informe técnico en formato pdf'}
                                    width={'5rem'} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton label={'DOCX'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenameDOCXuploaded}
                                        afterUpload={() => {
                                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                            if(f.isValid(result2.rutaDocumento)) {
                                              const arr2 = result2.rutaDocumento.split('/')
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                            } else {
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                            }
                                          })
                                        }}
                                        toolTip={'Subir docx actualizado del informe técnico '}
                                        width={'5rem'}
                                        disabled={formValues.firmado || formValues.firmando} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <Fragment>
                    <MyFileUploadButton label={'PDF (FIRMADO)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                        toolTip={'Subir documento firmado en formato pdf'}
                                        width={'9rem'}
                                        disabled={formValues.firmado || formValues.firmando || (formValues.docxLded === '')} />
                    {!formValues.firmando &&
                      <MyButtonBacan3 label={'FIRMAR'}
                                      icon={FileDownloadIcon}
                                      onClick={()=> {
                                        API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                          if(res?.length > 0) {
                                            const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                            const firmaTareaId = res[0].firmaTareaId
                                            const fv = {
                                              ...formValues,
                                              firmando:true,
                                              firmado:false,
                                              pdfSigned,
                                              firmaTareaId
                                            }
                                            setFormValues(fv)
                                            dispatch2({type: 'after-firmar', fv})
                                            getOnSave(setCounter,fv)()
                                          }
                                        })
                                      }}
                                      disabled={formValues.firmado || (formValues.docxLded === '')}
                                      toolTip={'firmar electronicamente el documento'}
                                      width={'9rem'} />
                    }
                    {
                      formValues.firmado && <MyButtonBacan3 label={''}
                                                            icon={FileDownloadIcon}
                                                            onClick={() => API.fetchDownloadPDF({
                                                              solicitudId: instanciaProceso?.solicitud?.id,
                                                              subfolder,
                                                              filename: filenamePDFsigned,
                                                            })}
                                                            toolTip={'Descarga informe técnico firmado'}
                                                            width={'3rem'} />
                    }
                  </Fragment>
                }
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={myPayload} />
        </TabPanel>
        <TabPanel value="3">
          <P0102Expediente payload={payload}/>
        </TabPanel>
        {
          f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
            <TabPanel value="4">
              <P0102PayloadRO payload={payload.solicitudOriginal.payloadSolicitud}/>
            </TabPanel> : null
        }
        {
          f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
            <TabPanel value="5">
              <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
            </TabPanel> : null
        }
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} >
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={getOnSave(setCounter)}
                              disabled={counter <= 0 || formValues.firmando} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                  ...toBPM
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'enviar'}
                              myTip={'Enviar Informe Técnico y continuar con proceso'}
                              disabled={counter > 0 || formValues.pdfSigned === '' || !formValues.firmado} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

export default P01InformeTecnicoSenescyt
