import GLOBALS from 'src/features/App/globals'
import Swal from 'sweetalert2';
import "../chat/swalestilo.css"
import { abrirModalRestaurar, cargarInvestigador, cargarRoles, cerrarModalUsuario, seleccionarInstitucion } from './sliceUsuarios';
import {f} from "../../commons";

const API = { sliceName: 'usuarios' }

const evaluarRegistro = (body) => {
    /*
        {
            "id": 1,
            "cedula": "0100998988",
            "tipoDocumento": "CEDULA",
            "nombresCompletos": "",
            "genero": "MASCULINO",
            "etnia": "",
            "nacionalidad": "ECUATORIANA",
            "codigoAreaCelular": 593,
            "celular": "0980731555",
            "codigoAreaTelefono": 593,
            "telefono": "",
            "direccion": "CÉSAR DÁVILA Y JUAN BAUTISTA VÁSQUEZ S.N.",
            "email": "napalm1920@hotmail.com",
            "organizacion": "OTRA INSTITUCIÓN",
            "registro": "REG-INV-14-00001",
            "estado": "EXPIRADO",
            "status": "ACTIVO",
            "username": "0100998988"
         }
     */
    const condicion1 = (body?.estado === "ACREDITADO")
    const condicion2 = (body?.status === "ACTIVO")
    const condicion3 = (body?.cedula?.length > 0) || ((body?.cedula?.length === 0) && (body?.pasaporte?.length > 0))
    const condicion4 = (body?.email?.length > 0 || body?.email?.length > 0)
    const condicion5 = (body?.nombresCompletos?.length > 0 || body?.nombre?.length > 0)
    const condicion6 = (body?.codigo?.length > 0 || body?.registro?.length > 0)

    const condicion = condicion1 && condicion2 && condicion3 && condicion4 && condicion5 && condicion6
    const html = condicion?'':(''+
      ((!condicion1)?'<p>Debe estar ACREDITADO ante SENESCYT.</p>':'') +
      ((!condicion2)?'<p>Debe estar ACTIVO.</p>':'') +
      ((!condicion3)?'<p>Debe tener número válido de cédula o número de pasaporte.</p>':'') +
      ((!condicion4)?'<p>Debe tener correo electrónico válido.</p>':'') +
      ((!condicion5)?'<p>Debe tener nombres completos.</p>':'') +
      ((!condicion6)?'<p>Debe tener código de registro válido.</p>':'')
    )
    const msg = condicion?'Investigador verificado correctamente':'Investigador con información inconsistente:'
    return {
        position: 'top-end',
        icon: condicion?'success':'error',
        title: msg,
        html: condicion?'':`<div>${html}</div>`,
        showConfirmButton: true,
        timer:condicion?2000:10000,
        customClass: {
            container: 'my-swal'
        },
        condicion,
    }
}

export const verificarInvestigadorRegistro = (info) => {
    return async (dispatch) => {
        const resp = await fetchInvestigadorRegistro(info);
        if (resp.status !== 204) {
           dispatch(dialogoConfirmacion('Investigador verificado correctamente'));
           const body = await resp.json();
           let mensaje = evaluarRegistro(body)
           const condicion = mensaje.condicion
           delete mensaje.condicion
           Swal.fire(mensaje)
           dispatch(cargarInvestigador(condicion?body:null));
        } else {
           console.log("no existe investigador")
           dispatch(dialogoError("El Investigador con registro: " + info + " no se encuentra registrado"))
           dispatch(cargarInvestigador(null));
        }
    }
}

export const verificarInvestigadorCedula = (info) => {
    return async (dispatch) => {
        const resp = await fetchInvestigadorCedula(info);
        if (resp.status !== 204) {
            dispatch(dialogoConfirmacion('Investigador verificado correctamente'));
            const body = await resp.json();
            let mensaje = evaluarRegistro(body)
            const condicion = mensaje.condicion
            delete mensaje.condicion
            Swal.fire(mensaje)
            dispatch(cargarInvestigador(condicion?body:null));
        } else {
            console.log("no existe investigador")
            dispatch(dialogoError("El Investigador con cédula: " + info + " no se encuentra registrado"))
            dispatch(cargarInvestigador(null));
        }
    }
}

export const verificarInvestigadorCorreo = (info) => {
    return async (dispatch) => {
        const resp = await fetchInvestigadorCorreo(info);
        if (resp.status !== 204) {
            const body = await resp.json();
            let mensaje = evaluarRegistro(body)
            const condicion = mensaje.condicion
            delete mensaje.condicion
            Swal.fire(mensaje)
            dispatch(cargarInvestigador(condicion?body:null));
        } else {
            // "no existe investigador"
            dispatch(dialogoError("El Investigador con correo: " + info + " no se encuentra registrado"))
            dispatch(cargarInvestigador(null));
        }
    }
}


export const verificarInvestigador = (info) => {
    return async (dispatch) => {
        const resp = await fetchInvestigador(info);
        const body = await resp.json();
        if (Object.keys(body).length !== 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Investigador verificado correctamente',
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                    container: 'my-swal'
                }
            })
            dispatch(cargarInvestigador(body));
        } else {
            console.log("no existe investigador")
            Swal.fire({
                position: "center",
                icon: 'error',
                title: "Error",
                html: "No se puede verificar al Investigador",
                showConfirmButton: true,
                customClass: {
                    container: 'my-swal'
                }
            })
        }
    }
}

export const retornaRoles = (organizacion) => {
    return async (dispatch) => {

        const resp = await fetchRoles(organizacion);
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(cargarRoles(body));
        } else {
            dispatch(cargarRoles([]));
            console.log("no existe roles")
        }
    }
}

export const guardarCuenta = (valores) => {
    console.log(':::: valores a guardar ', valores)
    return async (dispatch) => {
        try {
            const resp = await fetchSetCuenta(valores);
            const body = await resp.json();
            if (Object.keys(body).length !== 0) {
                const resp3 = await fetchSetPerfil(body.id, valores.rolId);
                const body3 = await resp3.json();
                if (Object.keys(body3).length !== 0) {
                    dispatch(cerrarModalUsuario());
                    Swal.fire({
                        position: "center",
                        icon: 'success',
                        title: `Se ha creado el usuario: ${valores.nombre}`,
                        html: `<p style="font-size:14px;font-weight:normal">Un mensaje ha sido enviado a su cuenta de correo: ${valores.mail}</p>`,
                        confirmButtonText: "ACEPTAR",
                        // buttons: ["ACEPTAR"],
                        customClass: {
                            container: 'my-swal'
                        },
                    })
                } else {
                    Swal.fire({
                        position: "center",
                        icon: 'error',
                        title: "Error",
                        html: "Error al asignar el perfil a la cuenta",
                        showConfirmButton: true,
                        customClass: {
                            container: 'my-swal'
                        }
                    })
                }
            } else {
                Swal.fire({
                    position: "center",
                    icon: 'error',
                    title: "Error",
                    html: "Error al crear la cuenta",
                    showConfirmButton: true,
                    customClass: {
                        container: 'my-swal'
                    }
                })
            }
        } catch (error) {
            console.log("error al guardar la cuenta", error)
        }
    }
}

export const cargarOrganizacion = (organizacion) => {
    return async (dispatch) => {

        const resp = await fetchOrganizacion(organizacion);
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(seleccionarInstitucion(body));
        } else {
            console.log("Error al cargar la institucion")
        }
    }
}

export const enviarCorreoRecuperacion = (correo) => {
    return async (dispatch) => {

        const resp = await sendCorreo(correo);

        if (resp.status === 200) {
            const body = await resp.json();

            if (body.resultado) {
                dispatch(dialogoConfirmacion("Correo enviado correctamente"))
                dispatch(abrirModalRestaurar(false))
            } else {
                dispatch(dialogoError("Error al enviar el correo"))
            }
        } else {
            dispatch(dialogoError("Error al enviar el correo"))
        }
    }
}

const dialogoConfirmacion = (texto) => {
    return () => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: texto || '',
            showConfirmButton: false,
            timer: 2000,
            customClass: {
                container: 'my-swal'
            }
        })
    }
}

const dialogoError = (texto) => {
    return () => {
        Swal.fire({
            position: "center",
            icon: 'error',
            title: "Error",
            html: texto || '',
            showConfirmButton: true,
            confirmButtonColor: 'rgba(54, 160, 184, 1)',
            customClass: {
                container: 'my-swal'
            }
        })
    }
}

const fetchInvestigadorRegistro = (registro) => {
    const url = `${GLOBALS.investigadoresWS}/registro/${registro}`
    return fetch(url)
}

const fetchInvestigadorCedula = (cedula) => {
    const url = `${GLOBALS.investigadoresWS}/cedula/${cedula}`
    return fetch(url)
}

const fetchInvestigadorCorreo = (correo) => {
    const url = `${GLOBALS.investigadoresWS}/email/${correo}`
    return fetch(url)
}

const fetchInvestigador = (cedulaOcorreo) => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/getemp?cedulaOcorreo=${cedulaOcorreo}`
    return fetch(url)
}

const fetchRoles = (organizacion) => {
    const url = `${GLOBALS.mainUrl}/v1/api/perfil/get?idorg=${organizacion}`
    return fetch(url)
}

const fetchSetCuenta = (valores, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/setemp`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "codigoUsuario": `${valores.codigo}`,
            "nombreUsuario": `${valores.nombre}`,
            "correoUsuario": `${valores.mail}`,
            "cedulaUsuario": `${valores.cedula}`,
            "pasaporteUsuario": `${valores.pasaporte}`,
            "usernameUsuario": `${valores.username}`,
            "cargoUsuario": `${valores.cargo}`,
            "telefonoUsuario": '',
            "tipo": `${valores.tipo}`,
            "estadoUsuario" : valores.status,
            "organizacion": {
                "id": `${valores.institucion}`,
                "codigoOrganizacion": `${valores.codigoOrganizacion}`,
                "nombreOrganizacion": `${valores.nombreOrganizacion}`,
                "descripcionOrganizacion": `${valores.descripcionOrganizacion}`,
                "ctlRefs": `${0}`
            },
            "ctlRefs": `${0}`
        })
    })
}

// const fetchSetPassword = (id, method = 'POST') => {
//     const url = `${GLOBALS.mainUrl}/api/v1/usuarios/setpsw?empId=${id}&passwd1=${'123.abc.'}&passwd2=${'123.abc.'}`
//     return fetch(url, {
//         method,
//         headers: {
//             'Content-type': 'application/json'
//         },
//         // body: JSON.stringify({
//
//         // })
//     })
// }

const fetchSetPerfil = (id, perfil, method = 'POST') => {

    const url = `${GLOBALS.mainUrl}/v1/api/perfilusuario/set?idusuario=${id}&idperfil=${perfil}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchOrganizacion = (id, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/getorg?idOrganizacion=${id}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const sendCorreo = (correo, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/reset?correo=${correo}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}


export default API
