import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from 'src/features/P01Solicitud/subcomponents/Solicitud'
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import GLOBALS from 'src/features/App/globals'
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {P0102Expediente} from "./P0102Expediente";

const P01SolicitudProyectoResolucion = ({id}) => {
  const section = 'ProyectoResolucion'
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  // const bandeja = useSelector(state => state.app.bandeja)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  payload.solicitudId = solicitud.id
  const funcionarios = useSelector(state => state.app.funcionarios)
  const aprueba = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const autorizador = funcionarios.filter(it => it.idPerfil === 164)[0]
  const today = format(new Date(), 'dd-MMM-yyyy', {locale: es})
  const emptyPayload = {
    numero:      solicitud.numeroSolicitud,
    fecha:       today,
    serial:      '',
    docx:        '',
    pdf:         '',
    docxLded:    '',
    pdfLded:     '',
  }
  // const solicitante = payload.Solicitante
  // const propuesta = payload.Propuesta
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  // const [dictamen, setDictamen] = useState({})
  // const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab);
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `resolucion-${nombreOrganizacion}`
  const filename = `resolucion-${formValues.numero}-${nombreOrganizacion}`
  // const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  // const filenamePDFuploaded = `${filename}-upld.pdf`
  const inputRef = useRef()
  const coordinador = funcionarios.filter(it => it.idPerfil === 164)[0]
  const autoridadSenescyt = funcionarios.filter(it => it.idPerfil === 1189)[0]

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }

  const labelTab2 = ['55000002_Activity_ElaborarProyectoResolucion'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab3 = ['55000002_Activity_ElaborarProyectoResolucion'].includes(id)?'Expediente':'Expediente Modificatoria'

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Proyecto de Resolución'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab}
                     aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label={labelTab2} value="2" />
              <Tab label={labelTab3} value="4" />
              {(f.isValid(payload?.solicitudOriginal))?<Tab label="Solicitud original" value="3" />:false}
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente original`} value="6"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Solicitud solicitud={solicitud}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Informes y dictámenes'} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                  <MyButtonBacan label={'SENESCYT'}
                                 onClick={() => API.fetchDownloadPDF({
                                   solicitudId:instanciaProceso?.solicitud?.id,
                                   // subfolder:subfolder,
                                   subfolder:'informe-tecnico-Senescyt',
                                   // filename:filenamePDFsigned
                                   filename:`informe-tecnico-${solicitud.numeroSolicitud}-Senescyt-firmado.pdf`
                                 })}
                                 icon={VisibilityIcon} />
                  <ButtonGroup variant={'contained'} sx={{width:'16rem'}}>
                    <MyButtonBacan label={'MAATE IT'}
                                   onClick={() => API.fetchDownloadPDF({
                                     solicitudId:instanciaProceso?.solicitud?.id,
                                     subfolder:'informe-tecnico-Maate',
                                     filename:`informe-tecnico-${solicitud.numeroSolicitud}-Maate-firmado.pdf`
                                   })}
                                   sx={{mr:'1rem'}}
                                   icon={VisibilityIcon} />
                    <MyButtonBacan label={'MAATE DT'}
                                   onClick={() => API.fetchDownloadPDF({
                                     solicitudId:instanciaProceso?.solicitud?.id,
                                     subfolder:'dictamen-tecnico-Maate',
                                     filename:`dictamen-tecnico-${solicitud.numeroSolicitud}-Maate-firmado.pdf`
                                   })}
                                   sx={{ml:'1rem'}}
                                   icon={VisibilityIcon} />
                  </ButtonGroup>
                  <MyButtonBacan label={'SENADI'}
                                 onClick={() => API.fetchDownloadPDF({
                                   solicitudId:instanciaProceso?.solicitud?.id,
                                   subfolder:'dictamen-tecnico-Senadi',
                                   filename:`dictamen-tecnico-${solicitud.numeroSolicitud}-Senadi-firmado.pdf`
                                 })}
                                 icon={VisibilityIcon }/>
                </Stack>
              </Grid>

              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Resolución'} />
              </Grid>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        API.secuenciaSet('RES').then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave({...formValues, serial:serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          // pdfSigned: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Resolución'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila(!esContrato?'56015021-1':'56015021-2').then(taggeDoc => {
                                          const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, mapping({
                                            solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                            payload,
                                            autorizador,
                                            coordinador,
                                            perfilUsuario,
                                            nombreOrganizacion,
                                            autoridadSenescyt,
                                            section: payload[section],
                                            aprueba,
                                          }))
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                            nombreOrganizacion,
                                          })
                                        })
                                      }}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton inputRef={inputRef}
                                          label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                  {/*{*/}
                  {/*  formValues.docx !== '' &&*/}
                  {/*  <ButtonGroup variant={'contained'}>*/}
                  {/*    <MyFileUploadButton inputRef={inputRef}*/}
                  {/*                        label={'PDF firmado'}*/}
                  {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                  {/*                        subfolder={subfolder}*/}
                  {/*                        fileName={filenamePDFsigned}*/}
                  {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                  {/*                        width={'9rem'} />*/}
                  {/*    {*/}
                  {/*      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}*/}
                  {/*                                                     icon={FileDownloadIcon}*/}
                  {/*                                                     onClick={() => API.fetchDownloadPDF({*/}
                  {/*                                                       solicitudId: instanciaProceso?.solicitud?.id,*/}
                  {/*                                                       subfolder,*/}
                  {/*                                                       filename: filenamePDFsigned,*/}
                  {/*                                                     })}*/}
                  {/*                                                     width={'3rem'}/>*/}
                  {/*    }*/}
                  {/*  </ButtonGroup>*/}
                  {/*}*/}
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="4">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {(f.isValid(payload?.solicitudOriginal)) ?
            <TabPanel value="3">
              <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
            </TabPanel> : false
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="6">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={ getOnSave()}
                                disabled={counter <= 0} />
                  <MySendButton onSend={ () => {
                                  const metadata = JSON.stringify({
                                      "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                      "subFolder": "ProyectoResolucion",
                                      "nombreArchivo": `PROYECTO-RESOLUCION-${instanciaProceso?.solicitud?.id}.pdf`,
                                    }
                                  )
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                label={'Enviar'}
                                disabled={counter > 0  || formValues.docxLded === ''} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}

const mapping = (vars) => {
  const { solicitudId, payload, coordinador, perfilUsuario, section, aprueba } = vars
  const autoridadSenescyt = vars.autoridadSenescyt
  // const arr = [...doc.matchAll(/\<\<[\w\.]*\>\>/g)].map(it => ({match:it[0], index: it.index})))
  // console.log(arr?.map(it => ({match:it[0], index: it.index})))
  const recInsitu = payload?.RecursosInSitu?.recursos?.map(it => it.scientificname)?.join(', ') + ', ' + payload?.RecursosExSitu?.centrosDocumentacion?.recursos?.map(it => it.scientificname)?.join(', ')
  const recExsitu = payload?.RecursosExSitu?.centrosDocumentacion?.map(it => it.recursos?.map(it2 => it2.scientificname)?.join((', ')))?.join(', ')
  let recs = []
  if(f.isValid(recInsitu)) recs.push(recInsitu)
  if(f.isValid(recExsitu)) recs.push(recExsitu)
  let acc = []
  if(payload?.AccesoConocimiento?.aConocimientoTradicional) acc.push('Acceso a conocimiento tradicional.')
  if(payload?.AccesoConocimiento?.clpi !== "") acc.push('CLPI: ' + payload?.AccesoConocimiento?.clpi)
  if(payload?.AccesoConocimiento?.contratoAccesoConocimiento !== "") acc.push('Contrato de acceso a conocimiento: '+ payload?.AccesoConocimiento?.contratoAccesoConocimiento)
  if(payload?.AccesoConocimiento?.ambitoComunitario) acc.push('Ambito comunitario.')
  if(payload?.AccesoConocimiento?.derivadosOSimilares) acc.push('Derivados o similares.')
  if(payload?.AccesoConocimiento?.etnozoologicos) acc.push('Etnozoologicos.')
  if(payload?.AccesoConocimiento?.medicinaTradicional) acc.push('Medicina tradeicional.')
  if(payload?.AccesoConocimiento?.practicasAncestrales) acc.push('Prácticas ancestrales.')
  if(payload?.AccesoConocimiento?.recursosBiologico) acc.push('Recursos biológicos.')
  if(payload?.AccesoConocimiento?.valoresReligiososCultutrales) acc.push('Valores religiosos culturales.')

  const result =  {
    '<<sistema.identificadorInformeTecnicoMaate>>':solicitudId,
    '<<autorizacion.vigencia>>': payload?.Propuesta?.plazo,
    '<<solicitante.identificador>>': solicitudId,
    '<<solicitante.idAcreditacion>>': payload?.Solicitante?.registro,
    '<<institucionApoyo.razonSocial>>': payload?.Propuesta?.apoyo,
    '<<solicitud.areaInvestigacion>>': payload?.Propuesta?.areaInvestigacion,
    '<<recursos.listaRecursos>>': recs.join(', '),
    '<<ubicacion.listaProvincias>>': payload?.RecursosInSitu?.provincias?.join(', '),
    '<<ubicacion.listaAreasProtegidas>>': payload?.RecursosInSitu?.areasProtegidas?.join(', '),
    '<<ubicacion.listaBosquesProtectores>>': payload?.RecursosInSitu?.bosquesProtectores?.join(', '),
    '<<solicitud.accesoConocimientoTradicional>>': acc?.join(' '),
    '<<plazoProyecto>>': payload?.Propuesta?.plazo + ' meses',
    '<<sistema.identificadorDictamenTecnicoMaate>>':solicitudId,
    '<<sistema.identificadorIDictamenTecnicoSenadi>>':solicitudId,
    '<<sistema.identificadorInformeTecnicoSenescyt>>':solicitudId,
    '<<sistema.identificadorResolucionSenescyt>>':solicitudId,
    '<<sistema.fechaTexto>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<sistema.fecha>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<solicitud.identificador>>':solicitudId,
    '<<solicitante.nombre>>':payload?.Solicitante?.nombresCompletos,
    '<<solicitud.nombreProyecto>>':payload?.Propuesta?.nombre,
    '<<solicitud.plazoProyecto>>':payload?.Propuesta?.plazo,
    '<<patrocinador>>':payload?.Propuesta?.apoyo,
    '<<sistema.nombreAutorizadorPrincipalSenescyt>>': aprueba?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorPrincipalSenescyt>>': aprueba?.nombrePerfil ?? '',
    '<<sistema.cargoAutorizadorPrincipalSenescyt>>': aprueba?.usuarioCargoUsuario?.toUpperCase() ?? '',
    '<<sistema.nombreAutorizadorSectorialMaate>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorSectorialMaate>>':coordinador?.nombrePerfil ?? '',
    '<<usuario.cargoAutorizadorSectorialMaate>>':coordinador?.descripcionPerfil ?? '',
    '<<sistema.nombreCoordinadorCasosSenescyt>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolCoordinadorCasosSenescyt>>':coordinador?.nombrePerfil ?? '',
    '<<sistema.cargoCoordinadorCasosSenescyt>>':coordinador?.usuarioCargoUsuario?.toUpperCase()  ?? '',
    '<<sistema.telefono>>':coordinador?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<sistema.correoElectronico>>':coordinador?.correoUsuario ?? '',
    '<<firman>>': JSON.stringify([
        {accion: 'Elaborado por',nombre:perfilUsuario?.usuario?.nombreUsuario ?? '', cargo:perfilUsuario?.perfil?.nombrePerfil ?? ''},
        {accion: 'Revisado por',nombre:coordinador?.nombreUsuario ?? '', cargo:coordinador?.usuarioCargoUsuario ?? ''},
        {accion: 'Aprobado por',nombre:aprueba?.nombreUsuario ?? '', cargo:aprueba?.usuarioCargoUsuario?.toUpperCase()},
    ]),
    '<<usuario.nombre>>':perfilUsuario?.usuario?.nombreUsuario ?? '',
    '<<usuario.rol>>':perfilUsuario?.perfil?.nombrePerfil ?? '',
    // '<<usuario.cargo>>':perfilUsuario?.perfil?.descripcionPerfil ?? '',
    '<<usuario.telefono>>':perfilUsuario?.usuario?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<usuario.correoElectronico>>':perfilUsuario?.usuario?.correoUsuario ?? '',
    '<<sistema.nombreAutoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '',
    '<<sistema.rolAutoridadSenescyt>>': autoridadSenescyt?.nombrePerfil ?? '',
    '<<sistema.cargoAutoridadSenescyt>>': autoridadSenescyt?.descripcionPerfil ?? '',
    '<<sistema.autoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '',
    '<<solicitud.objetivoProyecto>>': f.isValid(payload?.Propuesta?.objetivo)?payload?.Propuesta?.objetivo:'**',
    '<<serial>>': section.serial,
  }

  return result
}

export default P01SolicitudProyectoResolucion
