import React, {useEffect, useReducer, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Select, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from 'src/features/P01Solicitud/subcomponents/Solicitud'
import {
  handCompletarTareaCoordinador,
  handleClear,
  handleSaveSolicitud
} from 'src/features/App/sliceApp'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import MenuItem from "@mui/material/MenuItem"
import P0102API from './API'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {MyGobackButton, MySaveButton} from 'src/components/MyCommonButtons'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from 'src/components/MyReadOnlyTextField'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import {MySwitch} from "../../components/MySwitch";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import {MyTableRecursos} from "../../components/MyTableRecursos";
import {MyTableMuestras} from "../../components/MyTableMuestras";
import {muestras} from "./CONF";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import {MyCentroDocumentacion} from "../../components/MyCentroDocumentacion"
import {MyButtonAlert} from "../../components/MyButtonAlert"
import GLOBALS from 'src/features/App/globals'
import {MyTable as MyTableProductos} from "../../components/MyTable";
import {P0102Expediente} from "./P0102Expediente";

const range = (start, end) => {
  let ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
}
const range20 = range(1,20).map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)
const range5 = range(1,5).map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)

const reducer = (state, action) => {
  switch (action.type) {
    case "after-firmar":
      return {
        ...state,
        firmando: action.fv.firmando,
      }
    default:
      throw Error('Unknown action: ' + action.type)
  }
}

const P01SolicitudInformeTecnicoMAATE = ({id}) => {
  const dispatch = useDispatch()
  const funcionarios = useSelector(state => state.app.funcionarios)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const section = `InformeTecnico${perfilUsuario?.perfil?.organizacion?.nombreOrganizacion}`
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  let payload = JSON.parse(!!instanciaProceso?.solicitud?.payload?instanciaProceso?.solicitud?.payload:'{}')
  payload.solicitudId = solicitud.id
  const labelTab2 = ['55000002_Activity_ElaborarInformeTecnicoMaate'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab3 = ['55000002_Activity_ElaborarInformeTecnicoMaate'].includes(id)?'Expediente':'Expediente Modificatoria'
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:           today,
    identificador:   solicitud.numeroSolicitud,
    congruencia:     {ponderacion:1, calificacion:1},
    experiencia:     {ponderacion:1, calificacion:1},
    informacion:     {ponderacion:1, calificacion:1},
    resultados:      {ponderacion:1, calificacion:1},
    campo:           {ponderacion:1, calificacion:1},
    laboratorio:     {ponderacion:1, calificacion:1},
    recursosInSitu:  payload?.RecursosInSitu?.recursos,
    muestras:        payload?.RecursosInSitu?.muestras,
    centrosDocumentacion:  payload?.RecursosExSitu?.centrosDocumentacion,
    seAutoriza:      false,
    serial:          '',
    docx:            '',
    docxLded:        '',
    pdf:             '',
    pdfLded:         '',
    observaciones:   [''],

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//

  }
  // let myPayload = {
  //   "55000023_Activity_ElaborarInformeMaateWF15": payload?.Solicitud?.target?.payload,
  // }[instanciaTarea?.tareaCodigoTarea]
  // if(!f.isValid(myPayload)) {
  //   myPayload = payload
  // } else {
  //   myPayload = JSON.parse(myPayload)
  // }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const informeTecnico = !esContrato?'56015008-1':'56015008-2'
  function getOnSave(setCounter, fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:{...formValues}
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }

  const toBPM = {
    "criterioTecnicoMaate": false,
    "informeCompletoMaate": true,
    "informacionTerritorioMaate": false,
    "amplicacionInformacionMaate": false
  }
  const [counter, setCounter] = useState(-1)  // updating counter
  const [resultado, setResultado] = useState(0)
  const [ponderacion, setPonderacion] = useState(0)
  const coordinador = funcionarios.filter(it => it.idPerfil === 1144)[0]
  useEffect(() => {
    setCounter(counter + 1)
    const mResultado = formValues.laboratorio.ponderacion * formValues.laboratorio.calificacion / 5 +
      formValues.campo.ponderacion * formValues.campo.calificacion / 5 +
      formValues.resultados.ponderacion * formValues.resultados.calificacion / 5 +
      formValues.informacion.ponderacion * formValues.informacion.calificacion / 5 +
      formValues.experiencia.ponderacion * formValues.experiencia.calificacion / 5 +
      formValues.congruencia.ponderacion * formValues.congruencia.calificacion / 5
    setResultado(mResultado.toFixed(1))
    const mPonderacion = formValues.laboratorio.ponderacion +
      formValues.campo.ponderacion +
      formValues.resultados.ponderacion +
      formValues.informacion.ponderacion +
      formValues.experiencia.ponderacion +
      formValues.congruencia.ponderacion
    setPonderacion(mPonderacion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  useEffect(() => {
    setFormValues({...formValues, seAutoriza: resultado >= 80 && resultado <= 100})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultado])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const [recursoSelected, setRecursoSelected] = useState({})
  const subfolder = `informe-tecnico-${nombreOrganizacion}`
  const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  // const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  // const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const autorizador = funcionarios.filter(it => it.idPerfil === 1143)[0]

  // Test firma electronica ---\\
  useEffect(() => {
    if(formValues.firmando && !formValues.firmado) {
      const testFirmas = async () => {
        API.testFirmarPdf(instanciaTarea.id).then((res) => {
          if(res?.resultado === true) {
            API.testDocFirmadoPdf(instanciaTarea.id).then((signedPdf) => {
              if(f.isValidNotEmpty(signedPdf?.rutaDocumento)) {
                const ruta = signedPdf?.rutaDocumento?.split('/')
                const newSignedDoc = ruta[ruta.length - 1]
                const newSubFolder = ruta[ruta.length - 2]
                const fv = {
                  ...formValues,
                  firmando:false,
                  firmado:true,
                  pdfSigned: newSignedDoc,
                  rudaDocumentoFirmado: signedPdf?.rutaDocumento,
                  subfolder: newSubFolder
                }
                setFormValues(fv)
                getOnSave(setCounter,fv)()
              }
            })
          } else {
            console.log(`${instanciaTarea.id} aún no está firmado . . . `)
          }
        })
      }
      testFirmas().then(r => r)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.firmando])
  const [, dispatch2] = useReducer(reducer, {firmando: formValues.firmando})
  // Test firma electronica ---//

  return (
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <Stack
          direction={'row'}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            background: 'transparent'
          }}>
          <SectionTitle title={'Informe Técnico'} />
          <Typography sx={{m:'10px 10px 0 0', fontSize:'0.4rem'}}>{'id: ' + instanciaTarea.id}</Typography>
        </Stack>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label={labelTab2} value="2" />
            <Tab label={labelTab3} value="3" />
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <Tab label={`Solicitud original`} value="4"/> : null
            }
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <Tab label={`Expediente original`} value="5"/> : null
            }
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>
            {f.isValid(payload.T55000015_Activity_AsignarCasoMaateWF0102?.observaciones) && payload.T55000015_Activity_AsignarCasoMaateWF0102?.observaciones !== '' &&
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}
                       style={{padding: '1rem 24px 1rem 2rem'}}>
                  <MyButtonAlert label={'Directriz del caso'}
                                 color0={'#888888'}
                                 color1={'#888888'}
                                 messageTitle='Directriz del caso'
                                 messageContent={payload.T55000015_Activity_AsignarCasoMaateWF0102?.observaciones}/>
                </Stack>
              </Grid>
            }
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Calificación de la propuesta'} />
            </Grid>
            <Grid item xs={12} >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={'55%'}>Evaluación Técnica</TableCell>
                      <TableCell align="right" width={'15%'}>Ponderación</TableCell>
                      <TableCell align="right" width={'15%'}>Calificación</TableCell>
                      <TableCell align="right" width={'15%'}>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={'1'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Congruencia de la información presentada (título, antecedentes, justificación, objetivos)'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="congruencia.ponderacion"
                                value={formValues.congruencia.ponderacion}
                                onChange={(e) => {
                                  formValues.congruencia.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="congruencia.calificacion"
                                value={formValues.congruencia.calificacion}
                                onChange={(e) => {
                                  formValues.congruencia.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.congruencia.ponderacion*formValues.congruencia.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'2'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Experiencia relacionada del equipo técnico'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="experiencia.ponderacion"
                                value={formValues.experiencia.ponderacion}
                                onChange={(e) => {
                                  formValues.experiencia.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="experiencia.calificacion"
                                value={formValues.experiencia.calificacion}
                                onChange={(e) => {
                                  formValues.experiencia.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.experiencia.ponderacion*formValues.experiencia.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'3'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Información detallada de los especímenes a ser investigados'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="informacion.ponderacion"
                                value={formValues.informacion.ponderacion}
                                onChange={(e) => {
                                  formValues.informacion.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="informacion.calificacion"
                                value={formValues.informacion.calificacion}
                                onChange={(e) => {
                                  formValues.informacion.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.informacion.ponderacion*formValues.informacion.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'4'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Resultados esperados concuerdan con los objetivos propuestos'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="resultados.ponderacion"
                                value={formValues.resultados.ponderacion}
                                onChange={(e) => {
                                  formValues.resultados.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="resultados.calificacion"
                                value={formValues.resultados?.calificacion}
                                onChange={(e) => {
                                  formValues.resultados.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.resultados.ponderacion*formValues.resultados.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'5'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Metodología detallada para recolección y preservación de especímenes en campo'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="campo.ponderacion"
                                value={formValues.campo?.ponderacion}
                                onChange={(e) => {
                                  formValues.campo.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="campo.calificacion"
                                value={formValues.campo?.calificacion}
                                onChange={(e) => {
                                  formValues.campo.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.campo.ponderacion*formValues.campo.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'6'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Metodología detallada para trabajo en laboratorio'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="laboratorio.ponderacion"
                                value={formValues.laboratorio.ponderacion}
                                onChange={(e) => {
                                  formValues.laboratorio.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="laboratorio.calificacion"
                                value={formValues.laboratorio.calificacion}
                                onChange={(e) => {
                                  formValues.laboratorio.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.laboratorio.ponderacion*formValues.laboratorio.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'7'} sx={{height:'3rem'}}>
                      <TableCell />
                      <TableCell />
                      <TableCell >Resultado</TableCell>
                      <TableCell align="right" >{resultado}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {
              resultado > 100 &&
              <Grid item xs={12}>
                <Typography>El resultado no puede ser superior a 100</Typography>
              </Grid>
            }
            {
              ponderacion !== 100 &&
              <Grid item xs={12}>
                <Typography>La suma de ponderaciones debe ser igual a 100</Typography>
              </Grid>
            }
            {['in-situ', 'in-ex-situ'].includes(payload.Propuesta.recoleccionDeRecursos) && f.isValid(formValues.recursosInSitu) &&
              <>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Cantidades Autorizadas - Recursos IN-SITU'} />
                </Grid>
                <Grid item xs={12}>
                  <MyTableRecursos id={'recursosInSitu'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   muestras={formValues.muestras}
                                   columnName={'Recursos'}
                                   canEdit={false}
                                   setRecursoSelected={setRecursoSelected}
                                   canDeleteRow={false}
                                   mode={'in-situ'}/>
                </Grid>
                {
                  f.isValid(recursoSelected.scientificname) &&
                  <>
                    <Grid item xs={12} sx={dialog.titleContainer}>
                      <MySubtitle subtitle={'Muestras y submuestras'} />
                    </Grid>
                    <Grid item xs={12} >
                      {recursoSelected.scientificname}
                    </Grid>
                    <Grid item xs={12} >
                      <MyTableMuestras id={'muestras'}
                                       selected={recursoSelected}
                                       formValues={formValues}
                                       setFormValues={setFormValues}
                                       canEdit={true}
                                       mode={'cantidades'}
                                       muestras={muestras} />
                    </Grid>
                  </>
                }
              </>
            }
            {['ex-situ', 'in-ex-situ'].includes(payload.Propuesta.recoleccionDeRecursos) && f.isValid(formValues.centrosDocumentacion) &&
              <>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Cantidades Autorizadas - Recursos EX-SITU'} />
                </Grid>
                {
                  formValues.centrosDocumentacion.map(cd => {
                    return (
                      <Grid key={cd.id} item xs={12}>
                        <MyCentroDocumentacion id={cd.id}
                                               key={cd.id}
                                               canEdit={false}
                                               mode={'autorizar'}
                                               numeroSolicitud={solicitud?.id}
                                               formValues={formValues}
                                               setFormValues={(id, centro) => {
                                                 const idx = formValues.centrosDocumentacion.findIndex(it => it.id === id)
                                                 formValues.centrosDocumentacion[idx] = {...centro}
                                                 setFormValues({...formValues })
                                               }}
                                               verificarQTYAutorizada={true} />
                      </Grid>
                    )
                  })
                }
              </>
            }

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Observaciones'}/>
            </Grid>
            <Grid item xs={12}>
              <MyTableProductos id={'observaciones'}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                columnName={'observacion'}
                                canEdit={true}
                                addItem={() => {
                                  if (formValues['observaciones'].filter(it => it === '').length === 0) {
                                    const field = 'observaciones'
                                    const newSet = [...formValues[field], ...['']]
                                    const newFormValues = {...formValues, [field]: newSet}
                                    setFormValues(newFormValues)
                                  }
                                }}/>
            </Grid>
            {(resultado < 80) && (formValues.observaciones?.filter(it => f.isValidNotEmpty(it))?.length === 0) ?
              <Typography sx={{pl:'1rem'}}>Debe registrar al menos una observación.</Typography>:null
            }
            <Grid item xs={12}>
              <Box sx={{p:'2rem 0 2rem 2rem'}}>
                <MySwitch id={'seAutoriza'}
                          canEdit={false}
                          label={'¿Se aprueba el Informe Técnico y/o el uso de los recursos solicitados para las cantidades autorizadas?'}
                          formValues={formValues}
                          fullWidth={false}
                          handleChange={(e) => {
                            setFormValues({...formValues, seAutoriza: e.target.checked})
                          }} />
              </Box>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Informe'} />
            </Grid>
            <Grid item xs={12} style={{padding:'0'}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
                <MyReadOnlyTextField id={'fecha'} label={'Fecha'} icon={<CalendarMonthIcon  sx={dialog.readOnlyIcon} />} value={formValues.fecha} />
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = (!esContrato)?'ITMAT':'ITMATCPC'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave(setCounter,{...formValues, serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== ''  &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',

                                          // firma -- \\
                                          pdfSigned: '',
                                          firmando: false,
                                          firmado: false,
                                          // firma -- //
                                        })
                                      }}
                                      disabled={formValues.firmado || formValues.firmando}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                          const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla,
                                            nombreOrganizacion,
                                            P0102API.mapping({
                                              solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                              payload: {...payload, [section]: {...formValues}},
                                              autorizador,
                                              aprobar:coordinador,
                                              elaborar:perfilUsuario,
                                              nombreOrganizacion,
                                              section: payload[section]
                                            }))
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                          })
                                        })
                                      }}
                                      toolTip={'Generar informe técnico'}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => {
                                        API.fetchDownloadDOCX({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                        })
                                      }}
                                      toolTip={'Descargar informe técnico en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      toolTip={'Descargar informe técnico en formato pdf'}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={(fn) => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                                if(f.isValid(fn)) fn()
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          toolTip={'Subir informe técnico modificado en formato docx'}
                                          disabled={formValues.firmado || formValues.firmando}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyFileUploadButton label={'PDF (firmado)'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenamePDFsigned}
                                          afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                          toolTip={'Subir informe técnico firmado en formato pdf'}
                                          width={'9rem'}
                                          disabled={formValues.firmado || formValues.firmando || (formValues.docxLded === '')} />
                      <MyButtonBacan3 label={'FIRMAR'}
                                      icon={FileDownloadIcon}
                                      onClick={()=> {
                                        API.firmarPdf(instanciaTarea.id, subfolder, (formValues.pdfLded!=='')?formValues.pdfLded:formValues.pdf).then((res)=> {
                                          if(res?.length > 0) {
                                            const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                            const firmaTareaId = res[0].firmaTareaId
                                            const fv = {
                                              ...formValues,
                                              firmando:true,
                                              firmado:false,
                                              pdfSigned,
                                              firmaTareaId
                                            }
                                            setFormValues(fv)
                                            dispatch2({type: 'after-firmar', fv})
                                            getOnSave(setCounter,fv)()
                                          }
                                        })
                                      }}
                                      disabled={formValues.firmando || formValues.firmado ||(formValues.docxLded === '')}
                                      toolTip={'firmar electronicamente el documento'}
                                      width={'9rem'} />
                      {
                        formValues.firmado &&  <MyButtonBacan3 label={''}
                                                                       icon={FileDownloadIcon}
                                                                       onClick={() => API.fetchDownloadPDF({
                                                                         solicitudId: instanciaProceso?.solicitud?.id,
                                                                         subfolder,
                                                                         filename: formValues.pdfSigned,
                                                                       })}
                                                                       toolTip={'Descargar informe técnico firmado en formato pdf'}
                                                                       width={'3rem'} />
                      }
                    </>
                  }
                </Stack>
              </Stack>
            </Grid>
            {
              !formValues.docx === '' &&
              <Grid item xs={12} style={{padding: '0 24px 0 32px'}}>
                <Typography>Falta generar INFORME</Typography>
              </Grid>
            }

          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <P0102Expediente payload={payload}/>
        </TabPanel>
        {(f.isValid(payload?.solicitudOriginal)) ?
          <TabPanel value="4">
            <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
          </TabPanel> : false
        }
        {
          f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
            <TabPanel value="5">
              <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
            </TabPanel> : null
        }
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} >
              <Stack direction={'row'}
                     spacing={1}
                     justifyContent="space-between"
                     alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton id={'guardarInfTecnicoMAATE'}
                              onSave={getOnSave(setCounter)}
                              disabled={counter <= 0 || formValues.firmando} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                  ...toBPM
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'enviar'}
                              disabled={counter > 0 || formValues.docx === ''|| formValues.pdf === '' || !formValues.firmado || ((resultado < 80) && (formValues.observaciones?.filter(it => f.isValidNotEmpty(it))?.length === 0))} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default P01SolicitudInformeTecnicoMAATE
