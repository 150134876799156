
import { withFormik } from "formik";
import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import { InstitucionesSelect } from "./InstitucionesSelect";
import { RolSelect } from "./RolSelect";
import { guardarCuenta } from "./API";
import { validacionFuncionario } from "./validacionFuncionario";
import SendIcon from '@mui/icons-material/Send';
import { SelectCorreo } from "./SelectCorreo";

const form = props => {

  const theme = createTheme();

  const botonVerde = {
    ml: 0, mt: 2,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(14, 181, 14, 1)',
    "&:hover": {
      backgroundColor: 'rgba(18, 219, 18, 1)',
    }
  }

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <div >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
              <Grid container spacing={1}>

                <Grid item xs={12}>
                  <InstitucionesSelect name='institucion' tipo={2} />
                </Grid>

                <Grid item xs={12}>
                  <RolSelect name='rolId' />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="nombre"
                    name="nombre"
                    label="Apellidos y Nombres"
                    value={values.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.nombre ? errors.nombre : ""}
                    error={touched.nombre && Boolean(errors.nombre)}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid container sx={{ml: 1}}>
                  <Grid item xs={6} >
                    <TextField
                      id="textoInicial"
                      name="textoInicial"
                      label="Correo Electrónico"
                      value={values.textoInicial}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.textoInicial ? errors.textoInicial : ""}
                      error={touched.textoInicial && Boolean(errors.textoInicial)}
                      inputProps={{ style: { textTransform: 'lowercase' } }}
                      autoComplete="off"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectCorreo name='dominio' />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="cedula"
                    label="Cédula"
                    value={values.cedula}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.cedula ? errors.cedula : ""}
                    error={touched.cedula && Boolean(errors.cedula)}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="cargo"
                    label="Cargo"
                    value={values.cargo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.cargo ? errors.cargo : ""}
                    error={touched.cargo && Boolean(errors.cargo)}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid container spacing={1} justifyContent='right'>
                  <Button type="submit" color='success' variant="contained"
                    sx={botonVerde}
                    startIcon={<SendIcon />}
                  >
                    {'Enviar'}
                  </Button>
                </Grid>

              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div >
  );
};

const FuncionarioForm = withFormik({

  enableReinitialize: true,

  mapPropsToValues: (props) => ({
    textoInicial: "",
    nombre: "",
    codigo: "",
    cedula: "",
    dominio: "",
    cargo: "",
    tipo: props.tipo ? props.tipo : "",
    nombreOrganizacion: props.organizacion ? props.organizacion.nombreOrganizacion : "",
    codigoOrganizacion: props.organizacion ? props.organizacion.codigoOrganizacion : "",
    descripcionOrganizacion: props.organizacion ? props.organizacion.descripcionOrganizacion : "",
    institucion: props.organizacion ? props.organizacion.id : "",
  }),

  validationSchema: validacionFuncionario,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.rolesExiste.length !== 0 && props.registroDeCuenta(values);
    setSubmitting(false);
  }
})(form);

const mapStateToProps = (state) => ({
  rolesExiste: state.usuarios.roles,
  organizacion: state.usuarios.institucion,
  tipo: state.usuarios.tipoSeleccionado,
})

//función para usar un dispatch mediante props
const mapDispatchToProps = (dispatch) => ({
  registroDeCuenta: (values) => {
    values.mail = values.textoInicial + values.dominio
    values.username = values.textoInicial
    dispatch(guardarCuenta(values))
  }
})

export const FormFuncionarios = connect(mapStateToProps, mapDispatchToProps)(FuncionarioForm)
