import React from 'react';
import { withFormik } from "formik";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import { InstitucionesSelect } from "./InstitucionesSelect";
import { RolSelect } from "./RolSelect";
import { guardarCuenta } from "./API";
import SendIcon from '@mui/icons-material/Send';
import {Stack} from "@mui/system";
import {Typography} from "@mui/material";
import {f} from "../../commons";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";

const form = props => {

  const theme = createTheme();

  const botonVerde = {
    ml: 2, mt: 3,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(14, 181, 14, 1)',
    "&:hover": {
      backgroundColor: 'rgba(18, 219, 18, 1)',
    }
  }

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    // setFieldValue
  } = props;
  const condicion = (f.isValidNotEmpty(values.cedula) || f.isValidNotEmpty(values.pasaporte)) &&
    f.isValidNotEmpty(values.codigo) &&
    f.isValidNotEmpty(values.mail) &&
    f.isValidNotEmpty(values.nombre) &&
    (f.isValidNotEmpty(values.estado) && (values.estado === "ACREDITADO")) &&
    (f.isValidNotEmpty(values.status) && (values.status === "ACTIVO"))

  return (
    <div >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box sx={{
                  marginTop: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }} >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="nombre"
                    name="nombre"
                    label="Nombres"
                    value={values.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.nombre ? errors.nombre : ""}
                    error={touched.nombre && Boolean(errors.nombre)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="mail"
                             name="mail"
                             label="Correo Electrónico"
                             value={values.mail}
                             onChange={handleChange}
                             onBlur={handleBlur}
                             helperText={touched.mail ? errors.mail : ""}
                             error={touched.mail && Boolean(errors.mail)}
                             inputProps={{ style: { textTransform: 'lowercase' } }}
                             autoComplete="off"
                             margin="dense"
                             variant="outlined"
                             fullWidth
                             required
                             InputProps={{readOnly: true}} />
                </Grid>

                <Grid item xs={12}>
                  <InstitucionesSelect name='institucion' tipo={1} />
                </Grid>

                <Grid item xs={12}>
                  <RolSelect name='rolId' />
                </Grid>

                <Grid container spacing={1} justifyContent='right'>
                  <Stack direction={'column'}>
                    {(!condicion) ?
                      <>
                        <Typography sx={{m:'0.8rem 0.5rem 0 0', color:'darkred'}}>Información inconsistente</Typography>
                        {!(f.isValidNotEmpty(values.pasaporte) || f.isValidNotEmpty(values.cedula)) ?
                          <Typography sx={{m:'0 0.5rem 0 0'}}>Cédula o pasaporte debe ser válido</Typography>:null
                        }
                        {!f.isValidNotEmpty(values.codigo) ?
                          <Typography sx={{m:'0 0.5rem 0 0'}}>Código de registro debe ser válido</Typography>:null
                        }
                        {!f.isValidNotEmpty(values.mail) ?
                          <Typography sx={{m:'0 0.5rem 0 0'}}>Correo electrónico debe ser válido</Typography>:null
                        }
                        {!f.isValidNotEmpty(values.nombre) ?
                          <Typography sx={{m:'0 0.5rem 0 0'}}>Nombres y apellidos deben ser válidos</Typography>:null
                        }
                        {!f.isValidNotEmpty(values.estado) || (values.estado !== "ACREDITADO") ?
                          <Typography sx={{m:'0 0.5rem 0 0'}}>Debe estar ACREDITADO</Typography>:null
                        }
                        {!f.isValidNotEmpty(values.status) || (values.status !== "ACTIVO") ?
                          <Typography sx={{m:'0 0.5rem 0 0'}}>Debe estar ACTIVO</Typography>:null
                        }
                      </> : null
                    }
                    {(condicion) ?
                      <Button type="submit"
                              color='success'
                              variant="contained"
                              sx={botonVerde}
                              startIcon={<SendIcon />} >
                        {'Enviar'}
                      </Button> : null
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div >
  );
};

const InvestigadorForm = withFormik({

  enableReinitialize: true,

  mapPropsToValues: (props) => ({
    institucion: 510001,
    tipo: props.tipo ?? "",
    cedula: props.initialValues?.cedula ?? "",
    mail: props.initialValues?.email ?? "",
    nombre: props.initialValues?.nombresCompletos ?? "",
    codigo: props.initialValues?.registro ?? "",
    pasaporte: props.initialValues?.pasaporte ?? "",
    username: props.initialValues?.username ?? "",
    nombreOrganizacion: props.organizacion?.nombreOrganizacion ?? "",
    codigoOrganizacion: props.organizacion?.codigoOrganizacion ?? "",
    descripcionOrganizacion: props.organizacion?.descripcionOrganizacion ?? "",
    // nuevo
    genero: props.initialValues?.genero ?? "",
    etnia: props.initialValues?.etnia ?? "",
    nacionalidad: props.initialValues?.nacionalidad ?? "",
    estado: props.initialValues?.estado ?? "",
    status: props.initialValues?.status ?? "",
  }),

  // validationSchema: validacionFinca,

  handleSubmit: (values, { props, setSubmitting }) => {
    // console.log("values-> ", values)
    props.registroDeCuenta(values);
    setSubmitting(false);
  }
})(form);

const mapStateToProps = (state) => ({
  initialValues: state.usuarios.investigador,
  organizacion: state.usuarios.institucion,
  tipo: state.usuarios.tipoSeleccionado
})

//función para usar un dispatch mediante props
const mapDispatchToProps = (dispatch) => ({
  registroDeCuenta: (values) => {
    dispatch(guardarCuenta(values))
  }
})

export const FormDatos = connect(mapStateToProps, mapDispatchToProps)(InvestigadorForm)
