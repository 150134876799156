
import { withFormik } from "formik";
import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { abrirModalRestaurar } from "./sliceUsuarios";
import { validacionCorreo } from "./validacionCorreo";
import { Typography } from "@mui/material";
import {enviarCorreoRecuperacion} from "./API"

const form = props => {

    const theme = createTheme();

    const botonAzul = {
        mt: 1, ml: 2, maxWidth: 200,
        textTransform: 'none',
        borderRadius: '0.2rem',
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgba(54, 160, 184, 1)',
        "&:hover": {
            backgroundColor: 'rgba(54, 138, 184, 1)',
        }
    }

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
    } = props;

    return (
        <div >
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="md">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
                            <Grid container spacing={1}>


                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                    <Typography variant="body2" gutterBottom color="text.secondary">
                                        Le enviaremos un correo electrónico con más
                                        <br></br>
                                        instrucciones sobre como restablecer su contraseña
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="correo"
                                        name="correo"
                                        label="Correo Electrónico"
                                        value={values.correo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.correo ? errors.correo : ""}
                                        error={touched.correo && Boolean(errors.correo)}
                                        inputProps={{ style: { textTransform: 'lowercase' } }}
                                        autoComplete="off"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid container spacing={1} justifyContent='right' sx={{ mt: 2 }}>
                                    <Button type="submit" color='success' variant="contained"
                                        sx={botonAzul}
                                        startIcon={<SaveIcon />}
                                    >
                                        {'Enviar email'}
                                    </Button>
                                    <Button color='success' variant="contained"
                                        sx={botonAzul}
                                        startIcon={<HighlightOffIcon />}
                                        onClick={props.cerrarModalRestaurar}
                                    >
                                        {'Cerrar'}
                                    </Button>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </div >
    );
};

const RestaurarForm = withFormik({

    enableReinitialize: true,

    mapPropsToValues: (props) => ({
        correo: ""
    }),

    validationSchema: validacionCorreo,

    handleSubmit: (values, { props, setSubmitting }) => {
        props.envioCorreo(values);
        setSubmitting(false);
    }
})(form);

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    envioCorreo: (values) => {
        dispatch(enviarCorreoRecuperacion(values.correo))
    },
    cerrarModalRestaurar: () => {
        dispatch(abrirModalRestaurar(false));
    }
})

export const FormRestaurar = connect(mapStateToProps, mapDispatchToProps)(RestaurarForm)
