import React from 'react'
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import {Box, Stack} from '@mui/material'
import RobotoCondensedRegular from "../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {
  fetchInstanciaTareaInstanciaProceso,
  handleSetBandeja
} from 'src/features/App/sliceApp';
import AlarmIcon from '@mui/icons-material/Alarm';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export const MyTableBandeja = ({rows, page, setPage})  => {
  const dispatch = useDispatch()
  const pageSize = 5
  const pages = Math.ceil(rows/pageSize)
  if(page > pages && pages-1 >= 0) {
    setPage(pages-1)
    return <Typography>. . . e s p e r e  u n  m o m e n t o  p o r  f a v o r .</Typography>
  }

  return (
    <Box sx={{ pb: '24px', height:'auto', width: '100%', p:'24px 0 0 0' }}>
      <Stack direction="column" spacing={1}>
        <DataGrid rows={rows}
                  getRowId={(row) => row.id}
                  columns={columns(dispatch,fetchInstanciaTareaInstanciaProceso,handleSetBandeja)}
                  autoHeight={true}
                  rowHeight={28}
                  pageSize={pageSize}
                  rowsPerPageOptions={[pageSize]}
                  headerHeight={40}
                  getRowHeight={() => 'auto'}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeaders': {
                      // backgroundColor: "rgba(244,244,244,0.4)",
                      backgroundColor: "#33acff",
                      color: "#ffffff",
                      fontSize: '12px',
                      fontfamily: RobotoCondensedRegular,
                      fontweight: 'lighter',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                      '& .MuiDataGrid-row': {
                        backgroundColor: 'rgba(255, 255, 255, .7)',
                        fontSize: '10px',
                        fontfamily: RobotoCondensedRegular,
                        fontweight: 'lighter',
                        color: "#888888",
                      },
                      '& .Mui-selected': {
                        color: "#000000",
                      },
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                    },
                    [`.${gridClasses.cell}.normal`]: {
                      backgroundColor: 'rgba(158, 186, 4, 0.1)',
                      color: 'black',
                      fontWeight: 700,
                    },
                    [`.${gridClasses.cell}.nohot`]: {
                      backgroundColor: 'rgba(125, 218, 88, 0.2)',
                      color: 'black',
                      fontWeight: 700,
                    },
                    [`.${gridClasses.cell}.hot`]: {
                      backgroundColor: 'rgba(255, 222, 89, 0.2)',
                      color: 'black',
                      fontWeight: 700,
                    },
                    [`.${gridClasses.cell}.tooHot`]: {
                      backgroundColor: 'rgba(228, 8, 10, 0.2)',
                      color: 'black',
                      fontWeight: 700,
                    },
                  }}
                  experimentalFeatures={{ newEditingApi: true }}
                  onPageChange={(newPage) => setPage(newPage)}
                  page={page}
                  getCellClassName={(params) => {
                    switch(params.field) {
                      case 'instanciaProcesoSolicitudNombreProyecto':
                        return 'normal'
                      default: return ''
                    }
                  } }
        />
      </Stack>
    </Box>
  )
}

const columns  = (dispatch,fetchInstanciaTareaInstanciaProceso,handleSetBandeja) => {
  const mfColumn = {
    fontSize: '10px',
    fontfamily: RobotoCondensedRegular,
    fontweight: 'normal'
  }

  return [
    { field: 'id', headerName: 'id', width: 24, editable: false, hide: true },
    { field: 'instanciaProcesoSolicitudNombreProyecto',headerName: 'Nombre Proyecto', editable: false, width: 240 },
    {
      field: "instanciaProcesoNombreProceso",
      headerName: "Proceso/tarea",
      width: 200,
      rditablle: false,
      renderCell: (params) => (
        <Stack
          direction={'column'}
          sx={{cursor:'pointer', border:'solid 1px #AAAAAA', p:'6px', m:'4px', borderRadius:'2px', boxShadow:3}}
          onClick={() => {
            dispatch(fetchInstanciaTareaInstanciaProceso(params.id))
            dispatch(handleSetBandeja('entrada'))
          }}>
          <Typography sx={mfColumn}>{params.row.instanciaProcesoNombreProceso}</Typography>
          <Typography sx={{...mfColumn, mt:'4px'}} fontWeight={700}>{params.row.nombreTarea}</Typography>
        </Stack>
      ),
    },
    {
      field: "nombreUsuario",
      headerName: "Usuario/rol",
      width: 200,
      rditablle: false,
      renderCell: (params) => (
        <Stack direction={'column'}>
          <Typography sx={{...mfColumn, color: '#1F45FC'}}>{params.row.nombreUsuario}</Typography>
          <Typography sx={mfColumn} fontWeight={700}>{params.row.descripcionPerfil}</Typography>
        </Stack>
      ),
    },
    {
      field: "instanciaProcesoSolicitudNumeroSolicitud",
      headerName: "Solicitud",
      width: 120,
      rditablle: false,
      renderCell: (params) => (
        <Stack direction={'column'}>
          <Typography sx={mfColumn} fontWeight={700}>{params.row.instanciaProcesoSolicitudNumeroSolicitud}</Typography>
        </Stack>
      ),
    },
    {
      field: 'fecha',
      headerName: 'Fecha/días',
      editable: false,
      width: 120,
      renderCell: (params) => {
        const base = {
          fontWeight: 800,
          pt: '2px',
        }
        const nohot = {
          color: 'rgba(125, 218, 88, 1)',
          ...base
        }
        const hot = {
          color: 'rgba(255, 195, 0, 1)',
          ...base
        }
        const tooHot = {
          color: 'rgba(228, 8, 10, 1)',
          ...base
        }
        const fechaDiff = params.row.fechaDiff;
        let className = {};
        if (fechaDiff >= 21) {
          className = tooHot;
        } else if (fechaDiff < 21 && fechaDiff > 5) {
          className = hot;
        } else {
          className = nohot;
        }
        return (
          <Stack direction={'column'}>
            <Typography sx={mfColumn} fontWeight={700}>{params.row.fechaStr}</Typography>
            <Box display="flex" justifyContent="flex-start">
              <AlarmIcon sx={{fontSize: '16px', ...className}} />
              <Typography sx={{...mfColumn, fontSize:'8px', m:'6px 0 0 6px'}} >
                {`hace ${params.row.fechaDiff} días`}
              </Typography>
            </Box>
          </Stack>
        )},
    },
    {field: 'origen', headerName: 'Origen', editable: false, width: 120},
  ]
}
