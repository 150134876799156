import React, {Fragment, useEffect, useReducer, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Tab, Typography} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {accordeonBox, common, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import API from "src/features/App/API";
import {MyTextField} from "src/components/MyTextField";
import {MyAreaTextField} from "src/components/MyAreaTextField";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import {MyButtonBacan} from "../../components/MyButtonBacan";
import {MySubtitle} from "../../components/MySubtitle";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import {MySwitch} from "../../components/MySwitch";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import {Stack} from "@mui/system";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
// import RestartAltIcon from "@mui/icons-material/RestartAlt";
// import {MyTagged2Docx} from "../../components/MyTagged2Docx";
// import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {P0102Expediente} from "./P0102Expediente";

const reducer = (state, action) => {
  switch (action.type) {
    case "after-firmar":
      return {
        ...state,
        firmando: action.fv.firmando,
      }
    default:
      throw Error('Unknown action: ' + action.type)
  }
}

const P01SolicitudEmitirResolucion = ({id}) => {
  const section = 'Resolucion'
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  // const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `resolucion-${nombreOrganizacion}`
  const filename = `resolucion-${solicitud.numeroSolicitud}-${nombreOrganizacion}`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  // const filenameDOCXuploaded = `${filename}-upld.docx`
  // const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const emptyPayload = {
    identificador:    solicitud.numeroSolicitud,
    fecha:            today,
    seAprueba:        false,
    serial:           payload['ProyectoResolucion']?.serial,
    asunto:           '',
    contenido2:       '',
    docx:             filenameDOCX,
    pdf:              filenamePDF,
    docxLded:         payload?.ProyectoResolucion?.docxLded,
    pdfLded:          payload?.ProyectoResolucion?.pdfLded,

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//

  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  const inputRef = useRef()
  const funcionarios = useSelector(state => state.app.funcionarios)
  // const autorizador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  // const coordinador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  // const autoridadSenescyt = funcionarios.filter(it => it.idPerfil === 1189)[0]
  useEffect(() => {
    if(!formValues.seAprueba) {
      setFormValues({
        ...formValues,
        asunto: 'RESOLUCIÓN DE PROYECTO DE INVESTIGACIÓN NEGADA',
        contenido2: `Estimado ${payload.Solicitante.nombresCompletos},\n\n`
          + `Su solicitud Nro. ${solicitud.numeroSolicitud} para el desarrollo del proyecto de investigación titulado "${payload.Propuesta.nombre}", ha concluido su proceso de revisión, negándose la autorización.\n\n`
          + 'RESOLUCIÓN ACERCA DEL PROYECTO DE INVESTIGACIÓN APROBADA\n\n'
          // + 'Saludos cordiales,\n'
          + 'Sistema VUVIB.\n'
      })
    } else {
      setFormValues({
        ...formValues,
        asunto: 'RESOLUCIÓN DE PROYECTO DE INVESTIGACIÓN APROBADA',
        contenido2: `Estimado/a ${payload.Solicitante.nombresCompletos},\n\n`
          + `Su solictud Nro. ${solicitud.numeroSolicitud} para el desarrollo del proyecto de investigación titulado "${payload.Propuesta.nombre}", ha concluido su proceso de revisión de manera favorable.\n\n`
          // + 'Saludos cordiales,\n'
          + 'Sistema VUVIB.\n'
      })
    }
  }, [formValues['seAprueba']])

  const labelTab4 = ['55000002_Activity_EmitirResolucion'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab2 = ['55000002_Activity_EmitirResolucion'].includes(id)?'Expediente':'Expediente Modificatoria'

  const getOnSave = (setCounter, fv=null) => {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0)))
    };
  }

  // Test firma electronica ---\\
  useEffect(() => {
    if(formValues.firmando && !formValues.firmado) {
      const testFirmas = async () => {
        API.testFirmarPdf(instanciaTarea.id).then((res) => {
          if(res?.resultado === true) {
            API.testDocFirmadoPdf(instanciaTarea.id).then((signedPdf) => {
              if(f.isValidNotEmpty(signedPdf?.rutaDocumento)) {
                const ruta = signedPdf?.rutaDocumento?.split('/')
                const newSignedDoc = ruta[ruta.length - 1]
                const newSubFolder = ruta[ruta.length - 2]
                const fv = {
                  ...formValues,
                  firmando:false,
                  firmado:true,
                  pdfSigned: newSignedDoc,
                  rudaDocumentoFirmado: signedPdf?.rutaDocumento,
                  subfolder: newSubFolder
                }
                setFormValues(fv)
                getOnSave(setCounter,fv)()
              }
            })
          } else {
            console.log(`${instanciaTarea.id} aún no está firmado . . . `)
          }
        })
      }
      testFirmas().then(r => r)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.firmando])
  const [, dispatch2] = useReducer(reducer, {firmando: formValues.firmando})
  // Test firma electronica ---//

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Emitir Resolución'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label={labelTab4} value="2" />
              <Tab label={labelTab2} value="3" />
              {(f.isValid(payload?.solicitudOriginal))?<Tab label="Solicitud original" value="4" />:false}
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente original`} value="5"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Solicitud solicitud={solicitud}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Resolución'} />
              </Grid>
              <Grid item xs={6} style={{padding:'0 24px 0 32px'}}>
                <MyReadOnlyTextField id={'identificador'}
                                     label={'Identificador'}
                                     value={formValues['identificador']}
                                     icon={<AssignmentIndOutlinedIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
              </Grid>
              <Grid item xs={6} style={{padding:'0 24px 0 32px'}}>
                <MyReadOnlyTextField id={'fecha'}
                                     label={'Fecha'}
                                     value={formValues['fecha']}
                                     icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
              </Grid>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    width={'5rem'} />
                  </ButtonGroup>
                  <ButtonGroup variant={'contained'}>
                    {formValues.docx !== '' &&
                      <Fragment>
                        <MyFileUploadButton inputRef={inputRef}
                                            label={'PDF (firmado)'}
                                            solicitudId={instanciaProceso?.solicitud?.id}
                                            subfolder={subfolder}
                                            fileName={filenamePDFsigned}
                          // afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                            afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                            toolTip={'Subir documento firmado en formato pdf'}
                                            width={'9rem'}
                                            disabled={formValues.firmando || formValues.firmado}/>
                        {!formValues.firmando &&
                          <MyButtonBacan3 label={'FIRMAR'}
                                          icon={FileDownloadIcon}
                                          onClick={()=> {
                                            API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                              if(res?.length > 0) {
                                                const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                                const firmaTareaId = res[0].firmaTareaId
                                                const fv = {
                                                  ...formValues,
                                                  firmando:true,
                                                  firmado:false,
                                                  pdfSigned,
                                                  firmaTareaId
                                                }
                                                setFormValues(fv)
                                                dispatch2({type: 'after-firmar', fv})
                                                getOnSave(setCounter,fv)()
                                              }
                                            })
                                          }}
                                          disabled={formValues.firmado}
                                          toolTip={'firmar electronicamente el documento'}
                                          width={'9rem'} />
                        }
                        { formValues.firmado && <MyButtonBacan3 label={''}
                                                                         icon={FileDownloadIcon}
                                                                         onClick={() => API.fetchDownloadPDF({
                                                                           solicitudId: instanciaProceso?.solicitud?.id,
                                                                           subfolder,
                                                                           filename: filenamePDFsigned,
                                                                         })}
                                                                         toolTip={'Descargar el documento firmado'}
                                                                         width={'3rem'}/>
                        }
                      </Fragment>
                    }
                  </ButtonGroup>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Notificación'} />
              </Grid>
              <Grid item xs={12}>
                <MySwitch id='seAprueba'
                          label={'¿Solicitud Aprobada?'}
                          formValues={formValues}
                          canEdit={canEdit}
                          fullWidth={false}
                          handleChange={handleChange} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 0 0 24px'}}>
                <MyTextField id={'asunto'}
                             label={'Asunto *'}
                             formValues={formValues}
                             handleChange={handleChange}
                             canEdit={true} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 0 0 24px'}}>
                <MyAreaTextField id={'contenido2'}
                                 label={'Contenido *'}
                                 formValues={formValues}
                                 handleChange={handleChange}
                                 canEdit={true} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="3">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {(f.isValid(payload?.solicitudOriginal)) ?
            <TabPanel value="4">
              <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
            </TabPanel> : false
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="5">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyButtonBacan label={'Regresar'}
                                 icon={ArrowBackIcon}
                                 onClick={() => dispatch(handleClear())} />
                  <MyButtonBacan label={'Guardar'}
                                 icon={SaveOutlinedIcon}
                                 onClick={() => {
                                   payload[section]=formValues
                                   dispatch(handleSaveSolicitud(
                                     instanciaProceso?.id,
                                     payload,
                                     () => setCounter(0)
                                   ))
                                 }}
                                 disabled={counter === 0 || formValues.firmando} />
                  <MySendButton onSend={ () => {
                                  const metadata = JSON.stringify({
                                    "resolucionProcedente": formValues.seAprueba,
                                    "permiso": instanciaProceso.tipoInstanciaProceso !== "Contrato",
                                    "contrato": instanciaProceso.tipoInstanciaProceso === "Contrato",
                                    "adjunto": formValues.pdfSigned,
                                    "carpeta": subfolder,
                                    "asunto": formValues.asunto,
                                    "detalle": formValues.contenido2,
                                  })
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                label='Enviar'
                                disabled={counter > 0 || formValues.pdfSigned === '' || formValues.pdfSigned === '' || !formValues.firmado} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}

export default P01SolicitudEmitirResolucion
