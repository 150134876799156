import React, {useEffect, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {add, format, parse} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import API from "src/features/App/API";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import {SectionTitle} from "../../components/SectionTitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {AccountCircle} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Toolbar from "@mui/material/Toolbar";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {f} from "../../commons";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "./P0102PayloadRO";
import P0102API from './API'
import GLOBALS from 'src/features/App/globals'
import {P0102Expediente} from "./P0102Expediente";

const P01SolicitudElaborarPermiso = ({id}) => {
  const section = 'ElaborarPermiso'
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  const plantillaId = '56015023-1'
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:           today,
    serial:          '',
    identificador:   solicitud.numeroSolicitud,
    tiempoVigencia:  '' + payload.Propuesta.plazo + ' meses',
    docx:            '',
    pdf:             '',
    docxLded:        '',
    pdfLded:         '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const dResolucion = parse(slct.payload.Resolucion.fecha,'dd-MMMM-yyyy', new Date(), {locale: es})
  const plazo = Math.abs(Number(slct.payload.Propuesta.plazo))
  const tpartes = Math.trunc(plazo/12.0)
  let fechas=[`Fecha inicial: ${format(dResolucion, 'dd-MMM-yyyy', {locale: es})}`]
  for(let i=1; i<= tpartes; i++){
    fechas.push(`Reporte ${i}: ${format(add(dResolucion, {months: i * 12}), 'dd-MMM-yyyy', {locale: es})}` )
  }
  const dPlazo = add(dResolucion, {months: plazo})
  fechas.push(`Reporte final: ${format(dPlazo, 'dd-MMM-yyyy', {locale: es})}`)
  payload.fechas = fechas.join('{|}')
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `permiso-${nombreOrganizacion}`
  const filename = `permiso-${formValues.identificador}-${nombreOrganizacion}`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const funcionarios = useSelector(state => state.app.funcionarios)

  const autoridad = funcionarios.filter(it => it.idPerfil === 1189)[0]
  const revisar = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const aprobar = funcionarios.filter(it => it.idPerfil === 164)[0]
  // console.log('::: revisa y aprueba ',autoridad,perfilUsuario,revisar,aprobar)
  const autoridadSenescyt = funcionarios.filter(it => it.idPerfil === 1189)[0]
  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }

  const labelTab2 = ['55000002_Activity_ElaborarPermiso'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab4 = ['55000002_Activity_ElaborarPermiso'].includes(id)?'Expediente':'Expediente Modificatoria'

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Elaborar permiso de investigación'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label={labelTab2} value="2" />
              <Tab label={labelTab4} value="4" />
              {(f.isValid(payload?.solicitudOriginal))?<Tab label="Solicitud original" value="3" />:false}
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente original`} value="6"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} >
              <Solicitante solicitud={{solicitud: {payload}}} displayContact={false} />
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Resolución'} />
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                    <MyReadOnlyTextField id='identificador'
                                         label={'Identificador'}
                                         value={solicitud?.numeroSolicitud}
                                         icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyReadOnlyTextField id='fecha'
                                         label={'Fecha'}
                                         value={slct?.payload?.Resolucion?.fecha}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyButtonBacan label={'VER PDF'}
                                   onClick={() => API.fetchDownloadPDF({
                                     solicitudId:instanciaProceso?.solicitud?.id,
                                     subfolder:`resolucion-${nombreOrganizacion}`,
                                     filename:`resolucion-${solicitud.numeroSolicitud}-${nombreOrganizacion}-firmado.pdf`
                                   })}
                                   myTip={'Ver resolución'}
                                   icon={FileDownloadOutlinedIcon} />
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Permiso'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 24px 0 0.5rem'}}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                  <MyReadOnlyTextField id='identificador'
                                       label={'Identificador'}
                                       value={solicitud?.numeroSolicitud}
                                       icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  <MyReadOnlyTextField id='fecha'
                                       label={'Fecha de emisión'}
                                       value={format(dResolucion, 'dd-MMM-yyyy', {locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  <MyReadOnlyTextField id='plazo'
                                       label={'Vigencia'}
                                       value={`${plazo} meses`}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  <MyReadOnlyTextField id='fechaCaducidad'
                                       label={'Fecha de caducidad'}
                                       value={format(dPlazo, 'dd-MMM-yyyy', {locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                </Stack>
              </Grid>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'AUTIC'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave({...formValues, serial:serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== ''  &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          // serial: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila(plantillaId).then(taggeDoc => {
                                          const doc = MyTagged2Docx(
                                            taggeDoc?.contenidoPlantilla,
                                            nombreOrganizacion,
                                            P0102API.mapping({
                                              solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                              payload,
                                              autoridad: autoridad,
                                              elaborar:  perfilUsuario,
                                              revisar:   revisar,
                                              aprobar:   aprobar,
                                              nombreOrganizacion,
                                              autoridadSenescyt,
                                              section: payload[section],
                                            }))
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                            nombreOrganizacion,
                                          })
                                        })
                                      }}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="4">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {(f.isValid(payload?.solicitudOriginal)) ?
            <TabPanel value="3">
              <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
            </TabPanel> : false
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="6">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={getOnSave()}
                                disabled={counter <= 0 } />
                  <MySendButton onSend={() => {
                                  const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    // "perfilUsuarioSolicitud": Number(slct?.perfilUsuarioId),
                                  })
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                disabled={counter > 0 || formValues.docxLded === ''}
                                label={'enviar'} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}

export default P01SolicitudElaborarPermiso
